import { useTranslation } from 'react-i18next'
import { RecoilState, useRecoilValue } from 'recoil'
import { Dossier } from 'src/types'

export const useSubHeader = (atomDossier: RecoilState<Dossier>) => {
  const { t } = useTranslation()

  const dossier = useRecoilValue(atomDossier)

  const path = dossier?.prodottoDesc ? `/ ${dossier?.prodottoDesc}` : ''

  return {
    t,
    path,
  }
}
