import './policy-configuration-step-3.scss'

import { EditIcon, RightArrowIcon } from 'src/assets'
import { PolicyConfigurationProps } from 'src/types'
import { Button, InputCheckbox, InputText, Modal } from 'src/ui/widgets'
import { currencyFormatter, toDate } from 'src/utils'

import { usePolicyConfigurationStep3 } from './policy-configuration-step-3.hook'

export const PolicyConfigurationStep3 = ({
  formik,
  validationSchema,
}: PolicyConfigurationProps) => {
  const {
    t,
    insured,
    policy,
    showEditMail,
    setShowEditMail,
    setNewMail,
    showEditPhone,
    setShowEditPhone,
    setNewPhone,
    modalFormik,
    closePhoneMailModal,
    openPrivacyWebDoc,
  } = usePolicyConfigurationStep3({ formik, validationSchema })

  const { values, touched, errors, handleChange, handleBlur } = formik

  const {
    values: modalValues,
    touched: modalTouched,
    errors: modalErrors,
    handleChange: modalHandleChange,
    handleBlur: modalHandleBlur,
  } = modalFormik

  const Title = <h3 className="mb-5 fw-light font-32">{t('policy_configuration.step_3.title')}</h3>

  const PolicyDetails = (
    <div className="policy-details">
      <h2 className="text-uppercase font-12-bold c-7f7f7f">
        {t('policy_configuration.step_3.policy_data.title')}
      </h2>
      <div className="d-md-flex">
        <div className="col-md-6">
          <label className="font-12 c-7f7f7f">
            {t('policy_configuration.step_3.policy_data.start_date_label')}
          </label>
          <p className="mt-1">{toDate(policy.dataInizio)}</p>
        </div>
        <div className="col-md-6">
          <label className="font-12 c-7f7f7f">
            {t('policy_configuration.step_3.policy_data.end_date_label')}
          </label>
          <p className="mt-1">{toDate(policy.dataFine)}</p>
        </div>
      </div>
      <div className="d-md-flex">
        <div className="col-md-6">
          <label className="font-12 c-7f7f7f">
            {t('policy_configuration.step_3.policy_data.included_warranties_label')}
          </label>
          {policy.garanzie
            .filter((garanzia) => garanzia.selected)
            .map((garanzia) => (
              <p key={garanzia.idGaranzia} className="m-0">
                - {garanzia.descrizione}
              </p>
            ))}
        </div>
        <div className="col-md-6">
          <label className="font-12 c-7f7f7f">
            {t('policy_configuration.step_3.policy_data.total_price_label')}
          </label>
          <p className="mt-1">{currencyFormatter(policy.premioTotale)}</p>
        </div>
      </div>
    </div>
  )

  const ModalSection = (
    <Modal
      visible={true}
      classes="d-md-flex justify-content-center pb-5 pe-5 ps-5 pt-0"
      onClose={() => closePhoneMailModal()}
    >
      <div>
        <div className="d-md-flex justify-content-left">
          <h1>{t('policy_configuration.step_3.edit_email_modal.title')}</h1>
        </div>
        <div className="m-3 ms-0 font-14 c-939393">
          {t('policy_configuration.step_3.edit_email_modal.description')}
        </div>
        <InputText
          name="email"
          handleChange={modalHandleChange}
          handleBlur={modalHandleBlur}
          touched={modalTouched.email}
          value={modalValues.email}
          disabled={false}
          error={modalErrors.email}
          placeholder={t('policy_configuration.step_3.edit_email_modal.placeholder')}
          classes="mb-3"
        />
        <InputText
          name="confirmMail"
          handleChange={modalHandleChange}
          handleBlur={modalHandleBlur}
          touched={modalTouched.confirmMail}
          value={modalValues.confirmMail}
          disabled={false}
          error={
            modalValues.email === modalValues.confirmMail
              ? ''
              : t('policy_configuration.step_3.edit_email_modal.different_mail')
          }
          placeholder={t('policy_configuration.step_3.edit_email_modal.confirm_placeholder')}
          onPaste={(e) => e.preventDefault()}
        />
        <Button
          text={t('commons.continue')}
          onPress={() => setNewMail(modalValues.email)}
          classes="bg-7c2d35 p-4 justify-content-center mt-4"
          iconRight={RightArrowIcon}
          classesIconRight="filter-ffffff"
          classesText="text-uppercase font-16 c-ffffff me-3"
          disabled={
            !modalValues.email || modalErrors.email || modalValues.email !== modalValues.confirmMail
              ? true
              : false
          }
        />
      </div>
    </Modal>
  )

  const ModalPhoneSection = (
    <Modal
      visible={showEditPhone}
      classes="d-md-flex justify-content-center pb-5 pe-5 ps-5 pt-0"
      onClose={() => closePhoneMailModal()}
    >
      <div>
        <div className="d-md-flex justify-content-left">
          <h1>{t('policy_configuration.step_3.edit_phone_modal.title')}</h1>
        </div>
        <InputText
          name="phone"
          handleChange={modalHandleChange}
          handleBlur={modalHandleBlur}
          touched={modalTouched.phone}
          value={modalValues.phone}
          disabled={false}
          error={modalErrors.phone}
          placeholder={t('policy_configuration.step_3.edit_phone_modal.placeholder')}
        />
        <Button
          text={t('commons.continue')}
          onPress={() => setNewPhone(modalValues.phone)}
          classes="bg-7c2d35 p-4 justify-content-center mt-4"
          iconRight={RightArrowIcon}
          classesIconRight="filter-ffffff"
          classesText="text-uppercase font-16 c-ffffff me-3"
          disabled={!!modalErrors.phone}
        />
      </div>
    </Modal>
  )

  const CustomerDetails = (
    <div className="mt-5">
      <h2 className="text-uppercase font-12-bold c-7f7f7f">
        {t('policy_configuration.step_3.customer_data.title')}
      </h2>
      <div className="d-md-flex">
        <div className="col-md-6">
          <label className="font-12 c-7f7f7f">
            {t('policy_configuration.step_3.customer_data.name_label')}
          </label>
          <p className="mt-1">{insured?.nome}</p>
        </div>
        <div>
          <label className="font-12 c-7f7f7f">
            {t('policy_configuration.step_3.customer_data.surname_label')}
          </label>
          <p className="mt-1">{insured?.cognome}</p>
        </div>
      </div>
      <div className="d-md-flex">
        <div className="col-md-6">
          <label className="font-12 c-7f7f7f">
            {t('policy_configuration.step_3.customer_data.email_label')}
          </label>
          <p className="mt-1 mb-0">{insured?.email}</p>
          <Button
            text={t('policy_configuration.step_3.customer_data.edit_email_button')}
            iconSize={14}
            iconRight={EditIcon}
            classes="bg-none p-0 mb-3"
            classesIconRight="m-3 filter-7c2d35"
            classesText="c-7c2d35 text-uppercase text-nowrap font-12 fw-bold"
            onPress={() => setShowEditMail(true)}
          />
        </div>
        <div className="col-md-6">
          <label className="font-12 c-7f7f7f">
            {t('policy_configuration.step_3.customer_data.phone_label')}
          </label>
          <p className="mt-1 mb-0">{insured?.telefono}</p>
          <Button
            text={t('policy_configuration.step_3.customer_data.edit_phone_button')}
            iconSize={14}
            iconRight={EditIcon}
            classes="bg-none p-0"
            classesIconRight="m-3 filter-7c2d35"
            classesText="c-7c2d35 text-uppercase text-nowrap font-12 fw-bold"
            onPress={() => setShowEditPhone(true)}
          />
          {formik?.errors?.phone && (
            <p className="input-checkbox--error">{`${formik?.errors?.phone} `}</p>
          )}
        </div>
      </div>
      <div className="d-md-flex">
        <div className="col-md-6">
          <label className="font-12 c-7f7f7f">
            {t('policy_configuration.step_3.customer_data.birthdate_label')}
          </label>
          <p className="mt-1">{toDate(insured?.dataNascita) || t('commons.missing_value')}</p>
        </div>
        <div className="col-md-6">
          <label className="font-12 c-7f7f7f">
            {t('policy_configuration.step_3.customer_data.fiscal_code_label')}
          </label>
          <p className="mt-1">{insured?.codiceFiscale}</p>
        </div>
      </div>
    </div>
  )

  const ConsentSection = (
    <div id="consent-section" className="consent-section mt-5">
      <InputCheckbox
        name="consentInformativeSet"
        handleChange={handleChange}
        handleBlur={handleBlur}
        validationSchema={validationSchema}
        touched={touched?.consentInformativeSet}
        value={values?.consentInformativeSet}
        error={
          touched?.consentInformativeSet && errors.consentInformativeSet
            ? errors.consentInformativeSet
            : ''
        }
        label={t('policy_configuration.step_3.consent.label1')}
        classesLabel="m-0 font-16-bold d-flex align-items-center"
      />
      <InputCheckbox
        name="consentDigitalTransmission"
        handleChange={handleChange}
        handleBlur={handleBlur}
        validationSchema={validationSchema}
        touched={touched?.consentDigitalTransmission}
        value={values?.consentDigitalTransmission}
        error={
          touched?.consentDigitalTransmission && errors.consentDigitalTransmission
            ? errors.consentDigitalTransmission
            : ''
        }
        label={t('policy_configuration.step_3.consent.label2')}
        classesLabel="m-0 font-16-bold d-flex align-items-center"
      />
      <InputCheckbox
        name="consentPrivacy"
        handleChange={handleChange}
        handleBlur={handleBlur}
        validationSchema={validationSchema}
        touched={touched?.consentPrivacy}
        value={values?.consentPrivacy}
        error={touched?.consentPrivacy && errors.consentPrivacy ? errors.consentPrivacy : ''}
        label={t('policy_configuration.step_3.consent.labelPrivacy')}
        classesLabel="m-0 font-16-bold d-flex align-items-center"
      >
        <div className="ms-4 ps-1 font-14 c-7f7f7f">
          {t('policy_configuration.step_3.consent.descriptionPrivacy').split('informativa')[0]}
          informativa
          {t('policy_configuration.step_3.consent.descriptionPrivacy').split('informativa')[1]}
          <a href="#" className="c-7c2d35" onClick={() => openPrivacyWebDoc()}>
            informativa
          </a>
          {t('policy_configuration.step_3.consent.descriptionPrivacy').split('informativa')[2]}
        </div>
      </InputCheckbox>
      <InputCheckbox
        name="consentMarketing"
        handleChange={handleChange}
        handleBlur={handleBlur}
        validationSchema={validationSchema}
        touched={touched?.consentMarketing}
        value={values?.consentMarketing}
        error={touched?.consentMarketing && errors.consentMarketing ? errors.consentMarketing : ''}
        label={t('policy_configuration.step_3.consent.label3')}
        classesLabel="m-0 font-16 d-flex align-items-center"
      >
        <div className="ms-4 ps-1 font-14 c-7f7f7f">
          {t('policy_configuration.step_3.consent.description3').split('informativa')[0]}
          <a href="#" className="c-7c2d35" onClick={() => openPrivacyWebDoc()}>
            informativa
          </a>
          {t('policy_configuration.step_3.consent.description3').split('informativa')[1]}
        </div>
      </InputCheckbox>
      <InputCheckbox
        name="consentProfiling"
        handleChange={handleChange}
        handleBlur={handleBlur}
        validationSchema={validationSchema}
        touched={touched?.consentProfiling}
        value={values?.consentProfiling}
        error={touched?.consentProfiling && errors.consentProfiling ? errors.consentProfiling : ''}
        label={t('policy_configuration.step_3.consent.label4')}
        classesLabel="m-0 font-16 d-flex align-items-center"
      >
        <div className="ms-4 ps-1 font-14 c-7f7f7f">
          {t('policy_configuration.step_3.consent.description4').split('informativa')[0]}
          <a href="#" className="c-7c2d35" onClick={() => openPrivacyWebDoc()}>
            informativa
          </a>
          {t('policy_configuration.step_3.consent.description4').split('informativa')[1]}
        </div>
      </InputCheckbox>
      <InputCheckbox
        name="consentPersonalData"
        handleChange={handleChange}
        handleBlur={handleBlur}
        validationSchema={validationSchema}
        touched={touched?.consentPersonalData}
        value={values?.consentPersonalData}
        error={
          touched?.consentPersonalData && errors.consentPersonalData
            ? errors.consentPersonalData
            : ''
        }
        label={t('policy_configuration.step_3.consent.label5')}
        classesLabel="m-0 font-16 d-flex align-items-center"
      >
        <div className="ms-4 ps-1 font-14 c-7f7f7f">
          {t('policy_configuration.step_3.consent.description5').split('informativa')[0]}
          <a href="#" className="c-7c2d35" onClick={() => openPrivacyWebDoc()}>
            informativa
          </a>
          {t('policy_configuration.step_3.consent.description5').split('informativa')[1]}
        </div>
      </InputCheckbox>
    </div>
  )

  const Summary = (
    <div>
      {PolicyDetails}
      {CustomerDetails}
      {showEditMail && ModalSection}
      {showEditPhone && ModalPhoneSection}
      {ConsentSection}
    </div>
  )
  return (
    <div>
      {Title}
      <div className="bg-ffffff p-5">{Summary}</div>
    </div>
  )
}
