import { createContext, ReactNode, useContext } from 'react'
import { useRecoilState } from 'recoil'
import { atomCurrentStep } from 'src/storage'
import { ProgressContextType } from 'src/types'

const ProgressContext = createContext<ProgressContextType>({
  currentStep: 1,
  setCurrentStep: () => {},
})

export const useProgress = () => useContext(ProgressContext)

export const ProgressProvider = ({ children }: { children: ReactNode }) => {
  const [currentStep, setCurrentStep] = useRecoilState(atomCurrentStep)

  return (
    <ProgressContext.Provider value={{ currentStep, setCurrentStep }}>
      {children}
    </ProgressContext.Provider>
  )
}
