import './section6.scss'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { lowerLanguage } from 'src/i18n'
import { Paths } from 'src/navigation'
import { useGetDocument } from 'src/networking/terminals'
import { pathAtom, selectorCarousel } from 'src/storage'
import { Carousel, Message } from 'src/types'

export type ImageProps = {
  title: string
  description: string
  image: string
  link: Message
  carouselCode: string
}
export const useSection6 = () => {
  const { t } = useTranslation()
  const carousels = useRecoilValue(selectorCarousel)
  const { crossGate: retrieveDocument } = useGetDocument()

  const createProduct = (carousel: Carousel) => {
    const image = `data:image/jpeg;base64,${carousel.imagePath}`
    return {
      title: carousel.title[lowerLanguage],
      description: carousel.description[lowerLanguage],
      image: image,
      link: carousel.link,
      carouselCode: carousel.carouselCode,
    }
  }

  const productList = carousels?.map(createProduct) || []

  const [isMobile, setIsMobile] = useState(window.innerWidth < 810)

  const setPath = useSetRecoilState(pathAtom)

  useEffect(() => {
    setPath(location.pathname)
  }, [location.pathname, setPath])

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const ismobile = window.innerWidth < 810
        if (ismobile !== isMobile) setIsMobile(ismobile)
      },
      false
    )
  }, [isMobile])

  const reduceRecipes = (
    acc: ImageProps[][],
    cur: ImageProps,
    index: number,
    array: ImageProps[]
  ) => {
    const groupIndex = Math.floor(index / 3)
    if (!acc[groupIndex]) acc[groupIndex] = []
    acc[groupIndex].push(cur)

    if (index === array.length - 1 && acc[groupIndex].length < 3) {
      let i = 0
      while (acc[groupIndex].length < 3) {
        acc[groupIndex].push(array[i])
        i++
      }
    }

    return acc
  }

  const groupedProducts: ImageProps[][] = isMobile
    ? productList.map((item) => [item])
    : productList.reduce(reduceRecipes, [])

  const openDocument = (base64: string) => {
    if (base64) {
      const byteCharacters = atob(base64)
      const byteNumbers = new Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)
      const blob = new Blob([byteArray], { type: 'application/pdf' })
      const url = URL.createObjectURL(blob)
      window.open(url, '_blank')
    }
  }

  const getDocument = (docCode: string) => {
    if (docCode === 'CAR_RC_5') {
      window.open(Paths.OfficesAndStudios, '_blank')
      return
    }
    retrieveDocument(
      {
        uriParams: {
          docCode: docCode,
        },
      },
      {
        onSuccess: (response) => {
          openDocument(response?.carousel?.link[lowerLanguage])
        },
      }
    )
  }

  return {
    t,
    groupedProducts,
    productList,
    isMobile,
    getDocument,
  }
}
