import './payment-success.scss'

import { Documents } from 'src/ui/fragments'

import { Section6 } from '../../landing/sections'
import { usePaymentSuccess } from './payment-success.hook'
import { NextSteps } from './section/next-steps/next-steps'

export const PaymentSuccess = () => {
  const { t } = usePaymentSuccess()

  const SuccessHeader = () => {
    return (
      <div>
        <h1 className="col-md-8 mb-4 success-dynamic-size-title">{t('thank_you.success.title')}</h1>
        <p className="font-18 c-7f7f7f col-md-8 mb-4">{t('thank_you.success.description')}</p>
      </div>
    )
  }

  return (
    <>
      <div className="m-4 p-md-5">
        <SuccessHeader />
        <NextSteps />
        <hr className="policy-separator" />
        <Documents />
        <hr className="policy-separator" />
        <div>
          <Section6 />
        </div>
      </div>
    </>
  )
}
