import Cookies from 'js-cookie'
import { AwsGateCallbacks, AwsGateError, AwsGateRequest } from 'src/types'

import { useGetIdToken } from '../terminals/token/token.gates'
import { useAwsGateError } from './aws-gate-error.hook'
import { useAwsGateLoader } from './aws-gate-loader.hook'
import { useAwsGateRequest } from './aws-gate-request.hook'

const retryGetToken = async (getIdToken: any) => {
  let result = false

  await getIdToken(
    {
      headers: {
        'Content-Type': 'application/x-amz-json-1.1',
        'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth',
      },
      data: {
        AuthFlow: process.env.REACT_APP_AUTHFLOW || '',
        AuthParameters: {
          USERNAME: process.env.REACT_APP_USERNAME || '',
          PASSWORD: process.env.REACT_APP_PASSWORD || '',
        },
        ClientId: process.env.REACT_APP_CLIENT_ID || '',
        UserPoolId: process.env.REACT_APP_USER_POOL_ID || '',
      },
    },
    {
      onSuccess: (response: any) => {
        if (response?.AuthenticationResult?.TokenType && response?.AuthenticationResult?.IdToken) {
          Cookies.set(
            'IdToken',
            `${response?.AuthenticationResult?.TokenType} ${response?.AuthenticationResult?.IdToken}`
          )

          result = true
        } else {
          result = false
        }
      },
      onFailure: () => {
        result = false
      },
    }
  )

  return result
}

export const useAws = <T, D>(gateId: string) => {
  const { doRequest } = useAwsGateRequest<T, D>(gateId)
  const { gateError, saveGateError, removeGateError } = useAwsGateError(gateId)
  const { idle, pending, toIdle, toPending } = useAwsGateLoader(gateId)
  const { crossGate: getIdToken } = useGetIdToken()

  const crossGate = async (
    request: AwsGateRequest<T>,
    {
      onSuccess,
      onFailure = saveGateError,
      onIdle = toIdle,
      onPending = toPending,
    }: AwsGateCallbacks<D>
  ) => {
    try {
      onPending?.()
      onSuccess?.(await doRequest(request))
    } catch (err: any) {
      if (err?.message === 'Unauthorized' || err?.message === 'The incoming token has expired') {
        try {
          // console.log('[RETRY - GET TOKEN]: ', err?.message)
          const result = await retryGetToken(getIdToken)

          if (result) {
            const request_ = {
              ...request,
              headers: {
                ...request.headers,
                Authorization: Cookies.get('IdToken'),
              },
            }
            onSuccess?.(await doRequest(request_))
          } else {
            onFailure?.(err as AwsGateError)
          }
        } catch (error) {
          onFailure?.(err as AwsGateError)
        } finally {
          onIdle?.()
        }
      } else {
        onFailure?.(err as AwsGateError)
      }
    } finally {
      onIdle?.()
    }
  }

  return { gateError, removeGateError, idle, pending, crossGate }
}
