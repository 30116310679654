import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Paths } from 'src/navigation'

export const useGenericError = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return {
    t,
    goHome: () => navigate(Paths.Landing),
  }
}
