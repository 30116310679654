import './section6.scss'

import { Card, Carousel, Image } from 'react-bootstrap'
import { RightArrowIcon } from 'src/assets'
import { Button } from 'src/ui/widgets'

import { ImageProps, useSection6 } from './section6.hook'

export const Section6 = () => {
  const { t, groupedProducts, getDocument } = useSection6()

  const ImageSection = (item: ImageProps) => (
    <div
      className="position-relative"
      style={{ aspectRatio: '1', borderBottom: '1px solid #e2e2e2' }}
    >
      <div className="section6-status">{t('landing.section6.in_agenzia')}</div>
      <div className="section6-text ps-4 font-48-bold c-ffffff">{item?.title}</div>
      <Image src={item?.image} alt={item?.title} height="100%" width="100%" />
    </div>
  )

  const CarouselComponent = (
    <Carousel
      controls={false}
      indicators={true}
      interval={4000}
      className="carousel-outside slow-transition"
    >
      {groupedProducts.map((product: ImageProps[], index: number) => (
        <Carousel.Item key={index}>
          <div className="d-md-flex justify-content-between justify-xxl-content-center mx-xxl-5">
            {product.map((item: ImageProps, index: number) => (
              <Card
                key={index}
                className="mx-md-3 mx-xxl-5 card-container bg-ffffff d-flex flex-column"
              >
                {ImageSection(item)}
                <p className="font-18-roman c-7f7f7f p-4 pb-1">{item?.description}</p>
                <div className="mt-auto">
                  <Button
                    text={t('landing.section6.button')}
                    iconRight={RightArrowIcon}
                    classesIconRight="ms-3 filter-7c2d35"
                    iconSize={12}
                    classes="p-md-0 ms-md-4 d-flex bg-none mb-3"
                    classesText="font-12-roman fw-bold c-7c2d35 text-uppercase"
                    onPress={() => getDocument(item.carouselCode)}
                  />
                </div>
              </Card>
            ))}
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  )

  return (
    <div>
      <div className="font-48-light c-292929">{t('landing.section6.title')}</div>
      <div className="mt-2 d-flex align-items-center text-left font-18-roman c-646464 mb-5">
        {t('landing.section6.subtitle')}
      </div>
      {CarouselComponent}
    </div>
  )
}
