import Cookies from 'js-cookie'
import { useRecoilValue } from 'recoil'
import { useAws } from 'src/networking/aws'
import { atomDossier } from 'src/storage'
import { AwsGateCallbacks, AwsGateRequest, GetDocument } from 'src/types'

export const useGetDocument = <
  T extends GetDocument['request'],
  D extends GetDocument['response']
>() => {
  const hook = useAws<T, D>('getDocument')
  const dossier = useRecoilValue(atomDossier)
  return {
    ...hook,
    crossGate: (request: AwsGateRequest<T>, callbacks: AwsGateCallbacks<D>) => {
      request.headers = {
        ...request.headers,
        Authorization: Cookies.get('IdToken') || '',
        xDossierId: dossier?.dossierId,
      }
      return hook.crossGate(
        {
          method: 'get',
          path: `/dossier/${request?.uriParams?.docCode}`,
          ...request,
        },
        callbacks
      )
    },
  }
}
