import { atom } from 'recoil'
import { recoilPersist } from 'recoil-persist'
import { Dossier, InsuranceProposal, PostPolicy, QuestionnaireWrapper, Sede } from 'src/types'
import { FormikModal, FormikOfficesAndStudios, FormikPolicy, FormikSedi } from 'src/types/formik'
import { PaymentResponse } from 'src/types/networking/terminals/payment.types'
import { PolicyType } from 'src/ui/pages/offices-studios/sections/offices-studios-form/offices-studios-form.hook'

export const pathAtom = atom<string>({
  key: 'path',
  default: window.location.pathname,
})

export const persistAtomDossier = recoilPersist({
  key: 'dossier',
})

export const atomDossier = atom<Dossier>({
  key: 'atomDossier',
  default: undefined,
  effects_UNSTABLE: [persistAtomDossier.persistAtom],
})

export const persistAtomOfficesDossier = recoilPersist({
  key: 'officesDossier',
  storage: sessionStorage,
})

export const atomOfficesDossier = atom<Dossier>({
  key: 'atomOfficesDossier',
  default: undefined,
  effects_UNSTABLE: [persistAtomOfficesDossier.persistAtom],
})

export const persistAtomPolicy = recoilPersist({
  key: 'policies',
})

export const atomPolicy = atom<InsuranceProposal>({
  key: 'atomPolicy',
  default: undefined,
  effects_UNSTABLE: [persistAtomPolicy.persistAtom],
})

export const persistAtomPostPolicyResponse = recoilPersist({
  key: 'persistAtomPostPolicyResponse',
})

export const atomPostPolicyResponse = atom<PostPolicy['response']>({
  key: 'atomPostPolicyResponse',
  default: undefined,
  effects_UNSTABLE: [persistAtomPostPolicyResponse.persistAtom],
})

const persistAtomFormikPolicy = recoilPersist({
  key: 'formikPolicy',
  storage: sessionStorage,
})

export const atomFormikPolicy = atom<FormikPolicy>({
  key: 'atomFormikPolicy',
  default: {
    warranties: [],
    questions: [],
    consentInformativeSet: false,
    consentDigitalTransmission: false,
    consentPrivacy: false,
    consentMarketing: false,
    consentProfiling: false,
    consentPersonalData: false,
    email: '',
    phone: '',
  },
  effects_UNSTABLE: [persistAtomFormikPolicy.persistAtom],
})

export const persistAtomFormikSede = recoilPersist({
  key: 'formikSedi',
  storage: sessionStorage,
})

export const atomFormikSede = atom<FormikSedi>({
  key: 'atomFormikSede',
  default: {
    company: '',
  },
  effects_UNSTABLE: [persistAtomFormikSede.persistAtom],
})

const persistAtomFormikOfficesAndStudios = recoilPersist({
  key: 'formikOfficesAndStudios',
  storage: sessionStorage,
})

export const atomFormikOfficesAndStudios = atom<FormikOfficesAndStudios>({
  key: 'atomFormikOfficesAndStudios',
  default: {
    selectedPolicy: PolicyType.SILVER,
    company: '',
    email: '',
    confirmMail: '',
    phone: '',
    companies: [
      { companyName: '', vatNumber: '', address: '', zipCode: '', city: '', province: '' },
    ],
    consentInformativeSet: false,
  },
  effects_UNSTABLE: [persistAtomFormikOfficesAndStudios.persistAtom],
})

const persistAtomFormikModal = recoilPersist({
  key: 'formikModal',
  storage: sessionStorage,
})

export const atomFormikModal = atom<FormikModal>({
  key: 'atomFormikModal',
  default: {
    email: '',
    confirmMail: '',
    phone: '',
  },
  effects_UNSTABLE: [persistAtomFormikModal.persistAtom],
})

const persistSedi = recoilPersist({
  key: 'selectedSede',
  storage: sessionStorage,
})

export const atomSedi = atom<Sede[]>({
  key: 'atomSedi',
  default: [],
  effects_UNSTABLE: [persistSedi.persistAtom],
})

const persistAtomQuestionnaire = recoilPersist({
  key: 'questionnaire',
  storage: sessionStorage,
})

export const atomQuestionnaire = atom<QuestionnaireWrapper>({
  key: 'atomQuestionnaire',
  default: undefined,
  effects_UNSTABLE: [persistAtomQuestionnaire.persistAtom],
})

export const atomPaymentData = atom<PaymentResponse>({
  key: 'paymentData',
  default: undefined,
})

const persistAtomCurrentStep = recoilPersist({
  key: 'currentStep',
  storage: sessionStorage,
})

export const atomCurrentStep = atom<number>({
  key: 'atomCurrentStep',
  default: 1,
  effects_UNSTABLE: [persistAtomCurrentStep.persistAtom],
})
