import { RecoilState } from 'recoil'
import { Dossier, typedMemo } from 'src/types'

import { useSubHeader } from './sub-header.hook'

interface SubHeaderProps {
  dossier: RecoilState<Dossier>
}
export const SubHeader = typedMemo(({ dossier }: SubHeaderProps) => {
  const { t, path } = useSubHeader(dossier)

  return (
    <div className="p-4 p-md-5">
      <div className="c-939393 font-15 text-uppercase">{`${t('subheader.root')} ${path}`}</div>
    </div>
  )
})
