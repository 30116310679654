import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState, useRecoilValue } from 'recoil'
import i18n, { lowerLanguage } from 'src/i18n'
import { usePatchMail } from 'src/networking/terminals/email/email.gates'
import {
  atomFormikModal,
  atomPolicy,
  atomPostPolicyResponse,
  selectorConsentDocuments,
  selectorInsured,
} from 'src/storage'
import { Document, PolicyConfigurationProps } from 'src/types'
import * as yup from 'yup'

import { regexEmail, regexPhone } from '../handlers/validation-schemas.hook'

export const usePolicyConfigurationStep3 = (props: PolicyConfigurationProps) => {
  const { t } = useTranslation()
  const [showEditMail, setShowEditMail] = useState(false)
  const [showEditPhone, setShowEditPhone] = useState(false)

  const insured = useRecoilValue(selectorInsured)
  const [policy, setPolicy] = useRecoilState(atomPolicy)
  const postPolicyResponse = useRecoilValue(atomPostPolicyResponse)

  const { crossGate: patchMail } = usePatchMail()
  const documents = useRecoilValue(selectorConsentDocuments)

  const privacyWebDoc = documents?.find(({ docCode }) => docCode === 'CONS_RC_1')

  const formikModal = useRecoilValue(atomFormikModal)
  const validationSchema = yup.object({
    email: yup
      .string()
      .matches(regexEmail, i18n.t('commons.invalid_email'))
      .required(i18n.t('commons.email_required')),
    phone: yup.string().matches(regexPhone, i18n.t('commons.invalid_phone')),
  })

  const modalFormik = useFormik({
    initialValues: formikModal,
    validationSchema,
    onSubmit: () => {
      // do nothing
    },
  })

  useEffect(() => {
    if (
      (insured && insured?.email === null) ||
      insured?.email === undefined ||
      insured?.email === ''
    ) {
      setShowEditMail(true)
    } else {
      props.formik.setFieldValue('email', insured?.email)
      modalFormik.setFieldValue('email', insured?.email)
    }
    if (
      !(
        (insured && insured?.telefono === null) ||
        insured?.telefono === undefined ||
        insured?.telefono === ''
      )
    ) {
      props.formik.setFieldValue('phone', insured?.telefono)
      modalFormik.setFieldValue('phone', insured?.telefono)
    }
  }, [])

  const closePhoneMailModal = () => {
    modalFormik.setFieldValue('email', insured?.email)
    modalFormik.setFieldValue('confirmMail', '')

    modalFormik.setFieldValue('phone', insured?.telefono)
    setShowEditMail(false)
    setShowEditPhone(false)
  }

  const setNewMail = (email: string) => {
    setShowEditMail(false)

    patchMail(
      {
        uriParams: {
          idPolizza: postPolicyResponse.polizza.code,
        },
        body: {
          email: email,
          idAnagrafica: policy.assicurato?.idAssicurato.toString(),
        },
      },
      {
        onSuccess: (response) => {
          setPolicy((prevPolicy) => ({
            ...prevPolicy,
            assicurato: {
              ...prevPolicy.assicurato,
              email: response.email,
            },
          }))
          props.formik.setFieldValue('email', response.email)
        },
      }
    )
  }

  const setNewPhone = (phone: string) => {
    setShowEditPhone(false)

    patchMail(
      {
        uriParams: {
          idPolizza: postPolicyResponse.polizza.code,
        },
        body: {
          telefono: phone,
          idAnagrafica: policy.assicurato?.idAssicurato.toString(),
        },
      },
      {
        onSuccess: (response) => {
          setPolicy((prevPolicy) => ({
            ...prevPolicy,
            assicurato: {
              ...prevPolicy.assicurato,
              telefono: response.telefono,
            },
          }))
          props.formik.setFieldValue('phone', response.telefono)
        },
      }
    )
  }

  const openDocumentInNewTab = (document: Document | undefined) => {
    if (document) {
      const base64 = document.filePath[lowerLanguage]
      const byteCharacters = atob(base64)
      const byteNumbers = new Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)
      const blob = new Blob([byteArray], { type: 'application/pdf' })
      const url = URL.createObjectURL(blob)
      window.open(url, '_blank')
    }
  }

  return {
    t,
    insured,
    policy,
    showEditMail,
    setShowEditMail,
    setNewMail,
    showEditPhone,
    setShowEditPhone,
    setNewPhone,
    modalFormik,
    closePhoneMailModal,
    openPrivacyWebDoc: () => openDocumentInNewTab(privacyWebDoc),
  }
}
