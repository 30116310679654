import './modal.scss'

import { Image, Modal as RBModal } from 'react-bootstrap'
import { CloseIcon } from 'src/assets'
import { ModalProps, typedMemo } from 'src/types'

export const Modal = typedMemo(
  ({
    visible,
    children,
    onClose,
    classes,
    dialogClassName = 'modal-width',
    classesHead = 'd-flex justify-content-end pt-4 pe-4',
  }: ModalProps) => {
    return (
      <RBModal
        dialogClassName={dialogClassName}
        onHide={() => null}
        centered
        show={visible}
        animation={false}
      >
        {onClose && (
          <div className={classesHead}>
            <Image src={CloseIcon} fluid role="button" onClick={onClose} />
          </div>
        )}
        <RBModal.Body className={classes}>{children}</RBModal.Body>
      </RBModal>
    )
  }
)
