import { useTranslation } from 'react-i18next'
import { EditIcon, RightArrowIcon } from 'src/assets'
import { CustomerDetailsProps } from 'src/types'
import { Button, InputText, Modal } from 'src/ui/widgets'
import { toDate } from 'src/utils'

import { useCustomerDetails } from './customer-details.hook'

const CustomerDetails = ({ formik }: CustomerDetailsProps) => {
  const { t } = useTranslation()
  const {
    insured,
    showEditMail,
    setShowEditMail,
    setNewMail,
    showEditPhone,
    setShowEditPhone,
    setNewPhone,
    modalFormik,
    closePhoneMailModal,
  } = useCustomerDetails(formik)

  const {
    values: modalValues,
    touched: modalTouched,
    errors: modalErrors,
    handleChange: modalHandleChange,
    handleBlur: modalHandleBlur,
  } = modalFormik

  const ModalSection = (
    <Modal
      visible={true}
      classes="d-md-flex justify-content-center pb-5 pe-5 ps-5 pt-0"
      onClose={() => closePhoneMailModal()}
    >
      <div>
        <div className="d-md-flex justify-content-left">
          <h1>{t('customer_data.edit_email_modal.title')}</h1>
        </div>
        <div className="m-3 ms-0 font-14 c-939393">
          {t('customer_data.edit_email_modal.description')}
        </div>
        <InputText
          name="email"
          handleChange={modalHandleChange}
          handleBlur={modalHandleBlur}
          touched={modalTouched.email}
          value={modalValues.email}
          disabled={false}
          error={modalErrors.email}
          placeholder={t('customer_data.edit_email_modal.placeholder')}
          classes="mb-3"
        />
        <InputText
          name="confirmMail"
          handleChange={modalHandleChange}
          handleBlur={modalHandleBlur}
          touched={modalTouched.confirmMail}
          value={modalValues.confirmMail}
          disabled={false}
          error={
            modalValues.email === modalValues.confirmMail
              ? ''
              : t('customer_data.edit_email_modal.different_mail')
          }
          placeholder={t('customer_data.edit_email_modal.confirm_placeholder')}
          onPaste={(e) => e.preventDefault()}
        />
        <Button
          text={t('commons.continue')}
          onPress={() => setNewMail(modalValues.email)}
          classes="bg-7c2d35 p-4 justify-content-center mt-4"
          iconRight={RightArrowIcon}
          classesIconRight="filter-ffffff"
          classesText="text-uppercase font-16 c-ffffff me-3"
          disabled={
            !modalValues.email || modalErrors.email || modalValues.email !== modalValues.confirmMail
              ? true
              : false
          }
        />
      </div>
    </Modal>
  )

  const ModalPhoneSection = (
    <Modal
      visible={true}
      classes="d-md-flex justify-content-center pb-5 pe-5 ps-5 pt-0"
      onClose={() => closePhoneMailModal()}
    >
      <div>
        <div className="d-md-flex justify-content-left">
          <h1>{t('customer_data.edit_phone_modal.title')}</h1>
        </div>
        <InputText
          name="phone"
          handleChange={modalHandleChange}
          handleBlur={modalHandleBlur}
          touched={modalTouched.phone}
          value={modalValues.phone}
          disabled={false}
          error={modalErrors.phone}
          placeholder={t('customer_data.edit_phone_modal.placeholder')}
        />
        <Button
          text={t('commons.continue')}
          onPress={() => setNewPhone(modalValues.phone)}
          classes="bg-7c2d35 p-4 justify-content-center mt-4"
          iconRight={RightArrowIcon}
          classesIconRight="filter-ffffff"
          classesText="text-uppercase font-16 c-ffffff me-3"
          disabled={!!modalErrors.phone}
        />
      </div>
    </Modal>
  )

  const CustomerDetails = (
    <div className="mt-0">
      <h2 className="text-uppercase font-12-bold c-7f7f7f">{t('customer_data.title')}</h2>
      <div className="d-md-flex">
        <div className="col-md-6">
          <label className="font-12 c-7f7f7f">{t('customer_data.name_label')}</label>
          <p className="mt-1">{insured?.nome}</p>
        </div>
        <div>
          <label className="font-12 c-7f7f7f">{t('customer_data.surname_label')}</label>
          <p className="mt-1">{insured?.cognome}</p>
        </div>
      </div>
      <div className="d-md-flex">
        <div className="col-md-6">
          <label className="font-12 c-7f7f7f">{t('customer_data.email_label')}</label>
          <p className="mt-1 mb-0">{insured?.email}</p>
          <Button
            text={t('customer_data.edit_email_button')}
            iconSize={14}
            iconRight={EditIcon}
            classes="bg-none p-0 mb-3"
            classesIconRight="m-3 filter-7c2d35"
            classesText="c-7c2d35 text-uppercase text-nowrap font-12 fw-bold"
            onPress={() => setShowEditMail(true)}
          />
        </div>
        <div className="col-md-6">
          <label className="font-12 c-7f7f7f">{t('customer_data.phone_label')}</label>
          <p className="mt-1 mb-0">{insured?.telefono}</p>
          <Button
            text={t('customer_data.edit_phone_button')}
            iconSize={14}
            iconRight={EditIcon}
            classes="bg-none p-0"
            classesIconRight="m-3 filter-7c2d35"
            classesText="c-7c2d35 text-uppercase text-nowrap font-12 fw-bold"
            onPress={() => setShowEditPhone(true)}
          />
          {formik?.errors?.phone && (
            <p className="input-checkbox--error">{`${formik?.errors?.phone} `}</p>
          )}
        </div>
      </div>
      <div className="d-md-flex">
        <div className="col-md-6">
          <label className="font-12 c-7f7f7f">{t('customer_data.birthdate_label')}</label>
          <p className="mt-1">{toDate(insured?.dataNascita) || t('commons.missing_value')}</p>
        </div>
        <div className="col-md-6">
          <label className="font-12 c-7f7f7f">{t('customer_data.fiscal_code_label')}</label>
          <p className="mt-1">{insured?.codiceFiscale}</p>
        </div>
      </div>
    </div>
  )
  return (
    <>
      {CustomerDetails}
      {showEditMail && ModalSection}
      {showEditPhone && ModalPhoneSection}
    </>
  )
}

export default CustomerDetails
