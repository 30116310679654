import { useTranslation } from 'react-i18next'
import { object, string } from 'yup'
import * as yup from 'yup'

export const regexPhone = /^(\+\d{1,3}[-\s]?)?\d{3}[-\s]?\d{6,7}$/gm

export const useValidationSchemasOffices = () => {
  const { t } = useTranslation()

  const companySchema = yup.object().shape({
    companyName: yup.string().required(t('commons.required')),
    vatNumber: yup.string().required(t('commons.required')),
    address: yup.string().required(t('commons.required')),
    zipCode: yup.string().required(t('commons.required')),
    city: yup.string().required(t('commons.required')),
    province: yup.string().required(t('commons.required')),
  })

  return object({
    email: string().email(t('commons.invalid_email')).required(t('commons.email_required')),
    phone: string().matches(regexPhone, t('commons.invalid_phone')),
    companies: yup.array().of(companySchema),
    company: yup.string().required(t('commons.required')),
    consentInformativeSet: yup.boolean().oneOf([true], t('commons.required')),
  })
}
