import './policy-card.scss'

import { CloseIcon, DownloadIcon, InfoIcon, PlusIcon } from 'src/assets'
import { ID_PERIZIE_STIME } from 'src/config'
import { lowerLanguage } from 'src/i18n'
import { PolicyCardProps } from 'src/types'
import { Button, InputSelect, Modal } from 'src/ui/widgets'
import { currencyFormatter } from 'src/utils'

import { usePolicyCard } from './policy-card.hook'

export const PolicyCard = (props: PolicyCardProps) => {
  const { t, showInfoModal, setShowInfoModal, openDocument } = usePolicyCard()

  const {
    name,
    isSelected,
    maximumId,
    policy,
    setFieldValue,
    touched,
    errors,
    validationSchema,
    addRemovePolicy,
    disabled,
  } = props

  const InfoSection = (
    <div className="d-flex flex-row-reverse flex-md-row align-items-center col-md-4">
      <div>
        {policy?.infoAggiuntive?.[lowerLanguage] && (
          <Button
            iconRight={InfoIcon}
            classesIconRight="filter-7c2d35"
            iconSize={25}
            classes="bg-none p-1 pe-md-3"
            onPress={() => setShowInfoModal(true)}
          />
        )}
      </div>
      <div>
        <h4 className="font-18-bold c-292929 m-0">{policy?.descrizione}</h4>
        <div>
          {policy?.attachment && (
            <Button
              text={t('commons.conditions.download')}
              iconRight={DownloadIcon}
              iconSize={14}
              classes="font-12-semibold p-0 bg-none mt-2"
              classesText="c-7c2d35 text-uppercase text-nowrap"
              classesIconRight="filter-7c2d35 ms-2"
              onPress={() => openDocument(policy?.attachment?.filePath[lowerLanguage])}
            />
          )}
        </div>
      </div>
    </div>
  )

  const CeilingSection = (
    <div className="col-12 col-md-3 mb-4">
      <InputSelect
        name={name}
        options={
          policy?.massimali.map((el) => ({
            id: el.idMassimale.toString(),
            value: currencyFormatter(el.valoreMassimale),
          })) ?? []
        }
        validationSchema={validationSchema}
        setFieldValue={setFieldValue}
        touched={touched}
        value={maximumId}
        error={errors || ''}
        disabled={disabled || policy?.idGaranzia === ID_PERIZIE_STIME}
        label={t('policy_configuration.step_1.limit_label')}
        classesLabel="font-13-roman c-7f7f7f mt-4"
        classesInput="policy-card-input font-16-roman c-292929"
      />
    </div>
  )

  const AmountSection = (
    <div className="font-28-bold text-center col-md-3">
      {currencyFormatter(
        Number(policy?.massimali.find((el) => el.idMassimale.toString() === maximumId)?.premio)
      )}
    </div>
  )

  const ButtonSection = (
    <div className="d-flex col-md-2">
      <Button
        text={t(isSelected ? 'commons.remove' : 'commons.add')}
        iconRight={isSelected ? CloseIcon : PlusIcon}
        classesIconRight="ms-3 filter-7c2d35"
        iconSize={12}
        flavor="transparentPrimary"
        classes={`mt-3 mt-md-0 ${isSelected ? '' : 'p-3 button-active'}`}
        classesText="font-12-roman fw-bold c-7c2d35 text-uppercase"
        disabled={disabled || !maximumId}
        onPress={() =>
          addRemovePolicy(policy?.idGaranzia || -1, maximumId, isSelected ? 'remove' : 'add')
        }
      />
    </div>
  )

  const ModalSection = (
    <Modal
      visible={true}
      classes="d-md-flex justify-content-center pb-5 pe-5 ps-5 pt-0"
      onClose={() => setShowInfoModal(false)}
      dialogClassName="card-width"
    >
      <div>
        <div className="d-md-flex align-items-center justify-content-center">
          <h1>{policy?.descrizione}</h1>
        </div>
        <div className="m-3 font-18">
          {policy?.infoAggiuntive?.[lowerLanguage]?.split('\n').map((line, index) => (
            <p className="m-0 text-justify" key={index}>
              {line}
            </p>
          ))}
        </div>
      </div>
    </Modal>
  )

  return (
    <div
      className={`flex-column flex-md-row align-items-center policy-card col-md-12 p-4 p-md-0 ps-md-3 mb-4 ${
        isSelected ? 'active' : ''
      } ${disabled ? 'disabled' : ''}`}
    >
      {InfoSection}
      {CeilingSection}
      {AmountSection}
      {!policy?.obbligatoria && ButtonSection}
      {policy?.obbligatoria && <div className="col-md-2" />}
      {showInfoModal && ModalSection}
    </div>
  )
}
