import './input-checkbox.scss'

import { Image } from 'react-bootstrap'
import {
  CheckboxEmptyDisabled,
  CheckboxEmptyEnabled,
  CheckboxFullDisabled,
  CheckboxFullEnabled,
} from 'src/assets'
import { InputCheckboxProps, typedMemo } from 'src/types'
import { concatClasses, formatLabel } from 'src/utils'

const Sources = {
  'full-disabled': CheckboxFullDisabled,
  'full-enabled': CheckboxFullEnabled,
  'empty-disabled': CheckboxEmptyDisabled,
  'empty-enabled': CheckboxEmptyEnabled,
}

export const InputCheckbox = typedMemo(
  ({
    name,
    label,
    disabled,
    validationSchema,
    error,
    value,
    handleChange,
    handleBlur,
    classes,
    children,
    classesBox = 'input-checkbox--box',
    classesLabel = 'input-checkbox--label',
    classesError = 'input-checkbox--error',
  }: InputCheckboxProps) => {
    const allClassesLabel = concatClasses(
      classesLabel,
      label ? 'd-block' : 'd-none',
      disabled ? 'cursor-not-allowed color-disabled' : 'cursor-pointer'
    )

    const allClassesError = concatClasses(classesError, error ? '' : 'opacity-0')

    ////////////////////////////////////////////////////////////////////////////////////////////////////

    const Input = (
      <div>
        <input
          id={name}
          name={name}
          type="checkbox"
          checked={value}
          disabled={disabled}
          onChange={handleChange}
          onBlur={handleBlur}
          hidden
        />
      </div>
    )

    const Label = (
      <label htmlFor={name} className={allClassesLabel}>
        <Image
          src={Sources[`${value ? 'full' : 'empty'}-${disabled ? 'disabled' : 'enabled'}`]}
          className={classesBox}
        />
        {formatLabel(validationSchema, name, label)}
      </label>
    )

    const Error = <p className={allClassesError}>{`${error} `}</p>

    const Children = <div>{children}</div>

    ////////////////////////////////////////////////////////////////////////////////////////////////////

    return (
      <main id={`input-${name}`} className={classes}>
        {Input}
        {Label}
        {Children}
        {error && Error}
      </main>
    )
  }
)
