import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
  ID_PERIZIE_STIME,
  ID_POLIZZA_PRINCIPALE,
  ID_TUTELA_LEGALE,
  ID_TUTELA_LEGALE_CIVILE_PENALE,
} from 'src/config'
import { Paths } from 'src/navigation'
import { useGetPolicy, usePostPolicy } from 'src/networking/terminals'
import { atomDossier, atomFormikSede, atomPolicy, atomPostPolicyResponse } from 'src/storage'
import { PolicyConfigurationProps, Warranty } from 'src/types'
import { FormikWarranty } from 'src/types/formik'

export const usePolicyConfigurationStep1 = (props: PolicyConfigurationProps) => {
  const { t } = useTranslation()
  const { crossGate: getPolicy } = useGetPolicy()
  const { crossGate: postPolicy } = usePostPolicy()

  const { setFieldValue, values, setFieldTouched } = props.formik

  const [warranties, setWarranties] = useState<Warranty[]>([])
  const [tutela_legale_selected, setTutelaLegaleSelected] = useState(false)
  const [tutela_legale_civile_selected, setTutelaLegaleCivileSelected] = useState(false)

  const [policy, setPolicy] = useRecoilState(atomPolicy)

  const formikSede = useRecoilValue(atomFormikSede)

  const dossier = useRecoilValue(atomDossier)

  const setPostPolicyResponse = useSetRecoilState(atomPostPolicyResponse)

  const navigate = useNavigate()

  useEffect(() => {
    postPolicy(
      {
        body: {
          codeProdotto: dossier?.prodotto?.code,
          codeSede: formikSede.company,
        },
      },
      {
        onSuccess: (postResponse) => {
          setPostPolicyResponse(postResponse)
          getPolicy(
            {
              uriParams: {
                idPolizza: postResponse?.polizza?.code,
              },
            },
            {
              onSuccess: (data) => {
                if (
                  !data?.sede?.insegna ||
                  !data?.sede?.partitaIva ||
                  !data?.sede?.sede ||
                  !data.sede?.formaGiuridica ||
                  !data.sede.cap ||
                  !data?.sede?.comune
                ) {
                  navigate(Paths.GenericError)
                }
                setPolicy(data)
                const policy = data.garanzie.find((item) => item.obbligatoria === true)
                const warranties = data.garanzie.filter((item) => item.obbligatoria === false)
                const sortedWarranties = [policy, ...warranties].filter(
                  (warranty): warranty is Warranty => warranty !== undefined
                )
                setWarranties(sortedWarranties)
              },
            }
          )
        },
      }
    )
  }, [])

  const onAddRemovePolicy = (
    warrantyId?: number,
    maximumId?: string | undefined,
    operationType?: string
  ) => {
    if (!maximumId) return

    const currentWarranties = values.warranties || []
    //ID_TUTELA_LEGALE e ID_TUTELA_LEGALE_CIVILE_PENALE sono mutualmente eslclusivi
    if (warrantyId === ID_TUTELA_LEGALE) {
      if (
        currentWarranties.find((w) => {
          return w && w.warrantyId === ID_TUTELA_LEGALE_CIVILE_PENALE && w.isSelected
        })
      ) {
        return
      }
    }
    if (warrantyId === ID_TUTELA_LEGALE_CIVILE_PENALE) {
      if (
        currentWarranties.find((w) => {
          return w && w.warrantyId === ID_TUTELA_LEGALE && w.isSelected
        })
      ) {
        return
      }
    }

    const updatedWarranties = currentWarranties.map((warranty: FormikWarranty) => {
      if (warranty && warranty.warrantyId === warrantyId) {
        const obj = {
          ...warranty,
          isSelected: operationType === 'add',
        }
        return obj
      }
      return warranty
    })

    setFieldValue('warranties', updatedWarranties)
  }
  useEffect(() => {
    if (values?.warranties) {
      updateAtomPolicy()
      const tutela_legale_civile_selected =
        values.warranties.find(
          (w) => w?.isSelected && w?.warrantyId === ID_TUTELA_LEGALE_CIVILE_PENALE
        ) !== undefined
      const tutela_legale_selected =
        values.warranties.find((w) => w?.isSelected && w?.warrantyId === ID_TUTELA_LEGALE) !==
        undefined
      setTutelaLegaleCivileSelected(tutela_legale_civile_selected)
      setTutelaLegaleSelected(tutela_legale_selected)
    }
  }, [values?.warranties])

  const updateAtomPolicy = () => {
    if (!policy) return
    const policyCopy = JSON.parse(JSON.stringify(policy))

    const updatedGaranzie = policyCopy?.garanzie?.map((originalGaranzia: Warranty) => {
      const formikGaranzia = values?.warranties?.find(
        (g) => g?.warrantyId === originalGaranzia?.idGaranzia
      )
      if (formikGaranzia) {
        originalGaranzia.selected = formikGaranzia?.isSelected
        originalGaranzia.massimali = originalGaranzia?.massimali?.map((massimale) => {
          const formikMassimale = formikGaranzia?.maximumId === massimale?.idMassimale?.toString()
          if (formikMassimale) {
            massimale.selected = formikGaranzia?.isSelected
          } else {
            massimale.selected = false
          }
          return massimale
        })
      }
      return originalGaranzia
    })
    policyCopy.garanzie = updatedGaranzie
    setPolicy(policyCopy)
  }

  const customSetFieldValue = (
    field: string,
    value: number,
    index: number,
    forceSelected = false
  ) => {
    const obj = {
      warrantyId: warranties[index]?.idGaranzia,
      maximumId: value,
      isSelected: forceSelected,
    }
    if (index === 0) {
      // devo settare di conseguenza il massimale della garanzia perizie e stime (id 3)
      const warranty = warranties.find((warranty) => warranty.idGaranzia === ID_PERIZIE_STIME)

      const firstWarranty = warranties.find(
        (warranty) => warranty.idGaranzia === ID_POLIZZA_PRINCIPALE
      )
      const indexOfValue =
        firstWarranty?.massimali.findIndex(
          (massimale) => massimale.idMassimale.toString() === value.toString()
        ) || 0
      const massimale = warranty?.massimali[indexOfValue]
      const obj2 = {
        warrantyId: warranty?.idGaranzia,
        maximumId: massimale?.idMassimale.toString(),
        isSelected: false,
      }
      const field2 = `warranties.${warranties.findIndex(
        (warranty) => warranty.idGaranzia === ID_PERIZIE_STIME
      )}`

      setFieldValue(field2, obj2)
      setTimeout(() => setFieldTouched(field2, true, true))
    }
    return setFieldValue(field, obj)
  }

  return {
    t,
    warranties,
    onAddRemovePolicy,
    customSetFieldValue,
    tutela_legale_civile_selected,
    tutela_legale_selected,
  }
}
