import './policy-configuration-step-1.scss'

import { FormikErrors } from 'formik'
import { ID_TUTELA_LEGALE, ID_TUTELA_LEGALE_CIVILE_PENALE } from 'src/config'
import { PolicyConfigurationProps } from 'src/types'
import { FormikWarranty } from 'src/types/formik'

import { PolicyCard } from './policy-card/policy-card'
import { usePolicyConfigurationStep1 } from './policy-configuration-step-1.hook'

export const PolicyConfigurationStep1 = (props: PolicyConfigurationProps) => {
  const {
    t,
    warranties,
    onAddRemovePolicy,
    customSetFieldValue,
    tutela_legale_civile_selected,
    tutela_legale_selected,
  } = usePolicyConfigurationStep1(props)

  const { formik, validationSchema } = props

  const { values, touched, errors } = formik

  const Title = (
    <h3 className="mb-5 font-32-light c-292929">{t('policy_configuration.step_1.title')}</h3>
  )

  const Policy = (
    <div className="mh-8">
      {warranties[0]?.obbligatoria && (
        <div key={`warranties.0`}>
          <PolicyCard
            isSelected={values?.warranties?.[0]?.isSelected}
            name={`warranties.0`}
            maximumId={values?.warranties?.[0]?.maximumId}
            policy={warranties[0]}
            setFieldValue={(field, value) => customSetFieldValue(field, value, 0, true)}
            touched={
              Boolean(touched.warranties?.[0]) || (touched.warranties && !touched.warranties[0])
            }
            errors={values?.warranties?.[0]?.isSelected ? '' : t('commons.required')}
            validationSchema={validationSchema}
            addRemovePolicy={onAddRemovePolicy}
          />
        </div>
      )}
    </div>
  )

  const Warranties = (
    <div>
      <h3 className="text-uppercase c-7f7f7f font-12-bold mb-3">
        {t('policy_configuration.step_1.choose_warranty_label')}
      </h3>
      {warranties.map((warranty, index) => {
        const warrantyTouched = touched.warranties?.[index]
        const warrantyValue = values.warranties?.[index]
        const warrantyError = errors.warranties?.[index] as FormikErrors<FormikWarranty>
        const isSelected = values?.warranties?.find(
          (warranty_) => warranty_?.maximumId === warrantyValue?.maximumId
        )?.isSelected
        if (warranty.obbligatoria) {
          return null
        }

        const isTutelaLegale = warranty.idGaranzia === ID_TUTELA_LEGALE
        const isTutelaLegaleCivilePenale = warranty.idGaranzia === ID_TUTELA_LEGALE_CIVILE_PENALE

        return (
          <div key={`warranties.${index}`}>
            <PolicyCard
              isSelected={isSelected}
              name={`warranties.${index}`}
              maximumId={warrantyValue?.maximumId}
              policy={warranty}
              setFieldValue={(field, value) => customSetFieldValue(field, value, index)}
              touched={warrantyTouched?.maximumId}
              errors={warrantyError?.maximumId}
              validationSchema={validationSchema}
              addRemovePolicy={onAddRemovePolicy}
              disabled={
                (isTutelaLegale && tutela_legale_civile_selected) ||
                (isTutelaLegaleCivilePenale && tutela_legale_selected)
              }
            />
          </div>
        )
      })}
    </div>
  )

  return (
    <div>
      {Title}
      {Policy}
      {Warranties}
    </div>
  )
}
