import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'
import { atomPolicy } from 'src/storage'
import { toDate, toHour } from 'src/utils'

export const useNextSteps = () => {
  const { t } = useTranslation()
  const policy = useRecoilValue(atomPolicy)
  const fills = {
    startDate: toDate(policy?.dataInizio) || '',
    startHour: toHour(policy?.dataInizio) || '',
    endDate: toDate(policy?.dataFine) || '',
    endHour: toHour(policy?.dataFine) || '',
  }

  return {
    t,
    fills,
  }
}
