import './warning-card.scss'

import { Image } from 'react-bootstrap'
import { WarningIcon } from 'src/assets'
import { WarningCardProps } from 'src/types'

export const WarningCard = ({
  warningTitle,
  descriptionText,
  descriptionText2,
  classTitle,
  classDescription,
  classIcon,
}: WarningCardProps) => {
  return (
    <div className="warning-message d-md-flex justify-content-between align-items-center p-4">
      <div className="d-md-flex align-items-center">
        <Image src={WarningIcon} className={`warning-icon ${classIcon} filter-7f7f7f`} />
        <div>
          <b className={classTitle}>{warningTitle}</b>
          {descriptionText && <p className={classDescription}>{descriptionText}</p>}
          {descriptionText2 && <p className={classDescription}>{descriptionText2}</p>}
        </div>
      </div>
    </div>
  )
}
