import { Documents } from 'src/ui/fragments'

import { Error404 } from '../error-404/error-404'
import { Section6 } from '../landing/sections'
import { useOfficeStudios } from './offices-studios.hook'
import OfficesStudiosDescription from './sections/offices-studios-description/offices-studios-description'
import OfficesStudiosForm from './sections/offices-studios-form/offices-studios-form'
import OfficesStudiosHeader from './sections/offices-studios-header/offices-studios-header'
import OfficesStudiosWarranties from './sections/offices-studios-warranties/offices-studios-warranties'

const OfficesStudios = () => {
  const { enabled, pendingDossier } = useOfficeStudios()

  const Content = (
    <div className="m-4 p-md-5">
      <OfficesStudiosHeader />
      <hr className="policy-separator" />
      <OfficesStudiosDescription />
      <hr className="policy-separator" />
      <OfficesStudiosWarranties />
      <hr className="policy-separator" />
      <OfficesStudiosForm />
      <hr className="policy-separator" />
      <Documents />
      <hr className="policy-separator" />
      <div>
        <Section6 />
      </div>
    </div>
  )

  if (enabled && !pendingDossier) {
    return Content
  } else if (!enabled && !pendingDossier) {
    return <Error404 />
  }
  return <></>
}

export default OfficesStudios
