import { Route, Routes as ReactRoutes } from 'react-router'
import { Routes } from 'src/navigation'
import { PrivateRoute } from 'src/ui/fragments'

export const NavigationRoot = () => {
  return (
    <ReactRoutes>
      {Object.values(Routes)?.map(({ path, element, private: pvt }, index) => (
        <Route
          key={index}
          path={path}
          element={pvt ? <PrivateRoute element={element} /> : element}
        />
      ))}
    </ReactRoutes>
  )
}
