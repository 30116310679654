import { DownloadIcon } from 'src/assets'
import { lowerLanguage } from 'src/i18n'
import { Document } from 'src/types'
import { Button } from 'src/ui/widgets'

import { useDocuments } from './documents.hook'

export const Documents = () => {
  const { t, documents, openDocument } = useDocuments()

  const TextSection = (
    <div className="col-md-6">
      <h2 className="text-left font-48-light c-292929 text-nowrap">{t('documents.title')}</h2>
      <div className="mt-4 align-items-center text-left font-18-roman c-646464">
        <p>{t('documents.description')}</p>
      </div>
    </div>
  )

  const Document = ({ docCode, desc, filePath }: Document) => (
    <div key={docCode}>
      {docCode && (
        <div className="document-card mb-4">
          <div className=" align-items-center justify-content-between">
            <div className="font-24-roman c-292929">{desc?.[lowerLanguage]}</div>

            <Button
              text={t('commons.document.download')}
              iconRight={DownloadIcon}
              iconSize={12}
              classes="p-0 pt-3 bg-none"
              classesText="c-7c2d35 text-uppercase text-nowrap font-12-roman fw-bold"
              classesIconRight="filter-7c2d35 ms-2"
              onPress={() => {
                openDocument(filePath[lowerLanguage], desc?.[lowerLanguage])
              }}
            />
          </div>
        </div>
      )}
    </div>
  )

  const Documents = <div className="pt-4 pt-md-0">{<div>{documents?.map(Document)}</div>}</div>

  return (
    <div className="d-md-flex">
      <div className="col-md-6">{TextSection}</div>
      <div className="col-md-6">{Documents}</div>
    </div>
  )
}
