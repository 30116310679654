import './next-steps.scss'

import { Image } from 'react-bootstrap'
import { ActivationIcon, CertificateIcon, MailIcon } from 'src/assets'
import { fillTemplate } from 'src/utils'

import { useNextSteps } from './next-steps.hook'

export const NextSteps = () => {
  const { t, fills } = useNextSteps()

  return (
    <>
      <div className="bg-ffffff p-3 p-md-5">
        <h2 className="font-48-light">{t('thank_you.next_steps.title')}</h2>
        <div className="d-md-flex justify-content-between">
          <div className="col-md-4 pe-5">
            <div style={{ height: '5rem' }} className="mb-3">
              <Image src={MailIcon} width={38} className="filter-7c2d35 mt-5 mb-4" />
            </div>
            <h5>{t('thank_you.next_steps.c1.title')}</h5>
            <p className="c-7f7f7f t-justify">{t('thank_you.next_steps.c1.description')}</p>
          </div>
          <div className="col-md-4 pe-5">
            <div style={{ height: '5rem' }} className="mb-3">
              <Image src={ActivationIcon} width={30} className="filter-7c2d35 mt-5 mb-4" />
            </div>
            <h5>{t('thank_you.next_steps.c2.title')}</h5>
            <p className="c-7f7f7f t-justify">
              {fillTemplate(t('thank_you.next_steps.c2.description'), fills)}
            </p>
          </div>
          <div className="col-md-4 pe-5">
            <div style={{ height: '5rem' }} className="mb-3">
              <Image src={CertificateIcon} width={30} className="filter-7c2d35 mt-5 mb-4" />
            </div>
            <h5>{t('thank_you.next_steps.c3.title')}</h5>
            <p className="c-7f7f7f t-justify">{t('thank_you.next_steps.c3.description')}</p>
          </div>
        </div>
      </div>
    </>
  )
}
