import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import { ID_TUTELA_LEGALE, ID_TUTELA_LEGALE_CIVILE_PENALE } from 'src/config'
import { Paths } from 'src/navigation'
import { usePostPayment, usePostQuestionnaire, usePutPolicy } from 'src/networking/terminals'
import {
  atomDossier,
  atomFormikPolicy,
  atomFormikSede,
  atomPaymentData,
  atomPolicy,
  atomPostPolicyResponse,
  atomQuestionnaire,
  atomSedi,
  selectorAddress,
} from 'src/storage'
import { QuestionnaireWrapper, Sede } from 'src/types'
import { FormikPolicy } from 'src/types/formik'
import { AxeptaWindow } from 'src/types/window'

import { useProgress } from './sections'
import { useValidationSchemas } from './sections/handlers/validation-schemas.hook'

export const usePolicyConfiguration = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { currentStep, setCurrentStep } = useProgress()

  const [formikPolicy, saveFormikPolicy] = useRecoilState(atomFormikPolicy)
  const formikSede = useRecoilValue(atomFormikSede)

  const sedi = useRecoilValue(atomSedi)
  const dossier = useRecoilValue(atomDossier)
  const address = useRecoilValue(selectorAddress)
  const postPolicyResponse = useRecoilValue(atomPostPolicyResponse)

  const [policy, setPolicy] = useRecoilState(atomPolicy)
  const [paymentData, setPaymentData] = useRecoilState(atomPaymentData)
  const [questionnaire, setQuestionnaire] = useRecoilState(atomQuestionnaire)

  const validationSchemas = useValidationSchemas()

  const [showUnsuitablePurchase, setShowUnsuitablePurchase] = useState(false)
  const [showModalTutelaLegale, setShowModalTutelaLegale] = useState(false)

  const { crossGate: putPolicy } = usePutPolicy()
  const { crossGate: postPayment } = usePostPayment()
  const { crossGate: postQuestionnaire } = usePostQuestionnaire()

  const [selectedSede, setSelectedSede] = useState<Sede>()
  const [paymentNext, setPaymentNext] = useState(false)

  const formattedAddress = `${address?.indirizzo ? `${address.indirizzo} - ` : ''}${
    address?.comune || ''
  }${address?.provincia ? ` (${address.provincia}),` : ''} ${address?.cap || ''}`

  useEffect(() => {
    if (formikSede.company !== undefined) {
      const sede = sedi.find((sede) => sede.code.toString() === formikSede.company)
      setSelectedSede(sede)
    }
  }, [])

  useEffect(() => {
    if (paymentData?.paymentID) {
      const axeptaWindow: AxeptaWindow = window
      axeptaWindow?.callAxeptaClient?.()?.proceedToPayment?.(paymentData?.paymentID)

      setPaymentData({})
    }
  }, [paymentData])

  const formik = useFormik<FormikPolicy>({
    initialValues: formikPolicy,
    validationSchema: validationSchemas[currentStep - 1],
    validateOnMount: true,
    onSubmit: (values, { setTouched }) => {
      setTouched({})
      saveFormikPolicy(values)
    },
  })

  useEffect(() => {
    if (paymentNext) {
      setPaymentNext(false)
      postPayment(
        {
          params: {
            idPolizza: postPolicyResponse?.polizza?.code,
          },
          header: {
            dossierId: dossier?.dossierId,
          },
          body: {
            informativa_precontrattuale: formik?.values?.consentInformativeSet,
            tramissione_digitale: formik?.values?.consentDigitalTransmission,
            privacy: formik?.values?.consentPrivacy,
            marketing: formik?.values?.consentMarketing,
            profilazione: formik?.values?.consentProfiling,
            cessione_dati_to_itas: formik?.values?.consentPersonalData,
          },
        },
        {
          onSuccess: (response) => {
            if (response?.paymentID) {
              setPaymentData(response)
            }
          },
        }
      )
    }
  }, [paymentNext, dossier?.dossierId, postPolicyResponse?.polizza?.code, formik?.values])

  const onGoBackPressed = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1)
      formik.setErrors({})
    } else {
      navigate(Paths.Landing)
    }
  }
  const nextStep = () => {
    if (currentStep === 3 && formik.isValid) {
      setPaymentNext(true)
    } else if (currentStep === 3 && !formik.isValid) {
      const element = document.getElementById('consent-section')

      if (element && formik?.errors?.phone) {
        const offset = element.getBoundingClientRect().top + window.scrollY - 250
        window.scrollTo({ top: offset, behavior: 'smooth' })
      } else if (element) {
        const offset = element.getBoundingClientRect().top + window.scrollY - 50
        window.scrollTo({ top: offset, behavior: 'smooth' })
      }
    } else if (formik.isValid && currentStep === 1) {
      if (
        formik?.submitCount === 0 &&
        !formik?.values?.warranties.find(
          (warranty) =>
            (warranty?.warrantyId === ID_TUTELA_LEGALE ||
              warranty?.warrantyId === ID_TUTELA_LEGALE_CIVILE_PENALE) &&
            warranty?.isSelected
        )
      ) {
        setShowModalTutelaLegale(true)
        return
      }
      putPolicy(
        {
          uriParams: {
            idPolizza: postPolicyResponse.polizza.code,
          },
          body: policy,
        },
        {
          onSuccess(data) {
            setPolicy(data)
            setCurrentStep(currentStep + 1)
          },
        }
      )
    } else if (formik.isValid && currentStep === 2) {
      if (formik.values.questions?.length !== questionnaire?.questionario?.domande.length) return
      if (!questionnaire) return
      const questionnaireCopy = structuredClone(questionnaire) as QuestionnaireWrapper
      formik?.values?.questions?.forEach((selected, index) => {
        questionnaireCopy?.questionario.domande[index].risposte.forEach((risposta) => {
          risposta.selezionata = selected && risposta.idRisposta.toString() === selected.value
        })
      })
      postQuestionnaire(
        {
          uriParams: {
            idPolizza: postPolicyResponse.polizza.code,
          },
          body: questionnaireCopy as QuestionnaireWrapper,
        },
        {
          onSuccess(response) {
            if (response.questionario.isOk) {
              setQuestionnaire(response)
              setCurrentStep(currentStep + 1)
            } else {
              setShowUnsuitablePurchase(true)
            }
          },
        }
      )
    }
  }

  return {
    t,
    formik,
    policy,
    dossier,
    currentStep,
    selectedSede,
    formattedAddress,
    validationSchemas,
    showUnsuitablePurchase,
    nextStep,
    onGoBackPressed,
    setShowUnsuitablePurchase,
    setShowModalTutelaLegale,
    showModalTutelaLegale,
  }
}
