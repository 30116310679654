import { Image } from 'react-bootstrap'
import { CopertureAggiuntiveImage, NovitaImage } from 'src/assets'

import { useSection3 } from './section3.hook'
interface Description {
  text: string
  isNew?: boolean
}
export const Section3 = () => {
  const { t } = useSection3()

  const TextSection = ({
    title,
    subtitles,
    descriptions,
  }: {
    title: string
    subtitles: string[]
    descriptions: Description[]
  }) => (
    <div className="d-flex align-items-top h-100 col-md-10">
      <div>
        <h2 className="text-left font-48-light c-292929 mb-4">{title}</h2>
        <div className="mt-2 align-items-center text-left">
          {descriptions.map((desc, index) => (
            <div key={index} className="mb-2">
              <b className="font-18-bold c-646464">{subtitles[index]}</b>
              {desc.isNew && (
                <sup className="text-uppercase bg-7c2d35 c-ffffff font-12-bold d-inline-block px-2 ms-2">
                  {t('commons.new')}
                </sup>
              )}
              <p className="font-18 c-646464">{desc.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )

  return (
    <div>
      <div className="d-md-flex pb-md-5 col-md-12 w-100 mb-5">
        <div className="col-md-6 pe-md-5">
          <TextSection
            title={t('landing.section3_2.title')}
            subtitles={[
              t('landing.section3_2.subtitle2'),
              t('landing.section3_2.subtitle3'),
              t('landing.section3_2.subtitle4'),
              t('landing.section3_2.subtitle1'),
            ]}
            descriptions={[
              { text: t('landing.section3_2.description2') },
              { text: t('landing.section3_2.description3') },
              { text: t('landing.section3_2.description4') },
              { text: t('landing.section3_2.description1') },
            ]}
          />
        </div>
        <div className="col-md-6">
          <Image src={NovitaImage} />
        </div>
      </div>

      <div className="d-md-flex pb-md-5 col-md-12 w-100 section3-reverse">
        <div className="col-md-6 me-md-5">
          <Image src={CopertureAggiuntiveImage} />
        </div>
        <div className="col-md-6">
          <TextSection
            title={t('landing.section3.title')}
            subtitles={[
              t('landing.section3.subtitle1'),
              t('landing.section3.subtitle2'),
              t('landing.section3.subtitle3'),
              t('landing.section3_2.subtitle5'),
            ]}
            descriptions={[
              { text: t('landing.section3.description1') },
              { text: t('landing.section3.description2') },
              { text: t('landing.section3.description3') },
              { text: t('landing.section3_2.description5'), isNew: true },
            ]}
          />
        </div>
      </div>

      <p className="font-24 c-646464">{t('landing.section3.footer')}</p>
    </div>
  )
}
