type Mock = Record<number, unknown>

export type MockendConfig = Record<
  string,
  {
    enabled?: boolean
    status: number
    mocks: Mock
  }
>

export type MockendResponse = {
  status: number
  data: unknown
}

export class MockendError extends Error {
  response: MockendResponse

  constructor(gateId: string, mockResponse: MockendResponse) {
    super(`Mockend: gateId ${gateId} has status ${mockResponse?.status}`)
    this.response = mockResponse
  }
}
