import { useRecoilState } from 'recoil'

import { useRootOutcome } from './root-outcome.hook'
import { atomRootOutcomes } from './root-outcomes.atoms'

export const useRootOutcomes = () => {
  const [rootOutcomes, saveRootOutcomes] = useRecoilState(atomRootOutcomes)

  const topOutcomeId = Object.keys(rootOutcomes)?.[0] || ''

  const { rootOutcome: topRootOutcome, removeRootOutcome: removeTopRootOutcome } =
    useRootOutcome(topOutcomeId)

  const removeRootOutcomes = () => saveRootOutcomes({})

  return {
    rootOutcomes,
    topRootOutcome,
    removeRootOutcomes,
    removeTopRootOutcome,
  }
}
