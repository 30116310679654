import { Image } from 'react-bootstrap'
import { OfficesStudiosHeaderImage } from 'src/assets'

import { useOfficeStudiosHeader } from './offices-studios-header.hook'

const OfficesStudiosHeader = () => {
  const { t } = useOfficeStudiosHeader()
  const Status = (
    <div className="text-uppercase bg-7c2d35 c-ffffff font-12-bold d-inline-block px-2 mb-4 mt-md-5">
      {t('offices_studios.status')}
    </div>
  )

  const Title = <div className="mb-5 section1-dynamic-size-title">{t('offices_studios.title')}</div>

  const Subtitle = (
    <div className="c-646464 section1-dynamic-size-subtitle">{t('offices_studios.subtitle')}</div>
  )

  return (
    <>
      <div className="d-md-flex align-items-center">
        <div className="col-md-6 pe-3">
          {Status}
          {Title}
          {Subtitle}
        </div>
        <div className="col-md-6 p2">
          <Image src={OfficesStudiosHeaderImage} />
        </div>
      </div>
    </>
  )
}

export default OfficesStudiosHeader
