import { useSection2 } from './section2.hook'

export const Section2 = () => {
  const { t } = useSection2()

  return (
    <div className="d-grid">
      <div className="font-18-roman c-646464">{t('landing.section2.description')}</div>
      <br />
      <div className="font-18-roman c-646464">{t('landing.section2.description2')}</div>
    </div>
  )
}
