import './offices-studios-warranties.scss'

import { useTranslation } from 'react-i18next'

export const useOfficeStudiosWarranties = () => {
  const { t } = useTranslation()

  return {
    t,
  }
}
