import { useOfficeStudiosWarranties } from './offices-studios-warranties.hook'

const OfficesStudiosWarranties = () => {
  const { t } = useOfficeStudiosWarranties()

  const First = (
    <>
      <div className="font-48-roman c-414141">
        {t('offices_studios.warranties.description1.title')}
      </div>
      <br />
      <div className="font-18-roman c-646464">
        {t('offices_studios.warranties.description1.d1')}
      </div>
      <ul className="font-18-roman c-646464 mb-0">
        <li>{t('offices_studios.warranties.description1.list.l1')}</li>
        <li>{t('offices_studios.warranties.description1.list.l2')}</li>
        <li>{t('offices_studios.warranties.description1.list.l3')}</li>
        <li>{t('offices_studios.warranties.description1.list.l4')}</li>
        <li>{t('offices_studios.warranties.description1.list.l5')}</li>
        <li>{t('offices_studios.warranties.description1.list.l6')}</li>
        <li>{t('offices_studios.warranties.description1.list.l7')}</li>
      </ul>
      <div className="font-18-roman c-646464">
        {t('offices_studios.warranties.description1.d2')}
      </div>
    </>
  )

  const Second = (
    <>
      <div className="font-48-roman c-414141">
        {t('offices_studios.warranties.description2.title')}
      </div>
      <br />
      <div className="font-18-roman c-646464">
        {t('offices_studios.warranties.description2.d1')}
      </div>
    </>
  )

  const Third = (
    <>
      <div className="font-48-roman c-414141">
        {t('offices_studios.warranties.description3.title')}
      </div>
      <br />
      <div className="font-18-roman c-646464">
        {t('offices_studios.warranties.description3.d1')}
      </div>
      <ul className="font-18-roman c-646464">
        <li>{t('offices_studios.warranties.description3.list.l1')}</li>
        <li>{t('offices_studios.warranties.description3.list.l2')}</li>
        <li>{t('offices_studios.warranties.description3.list.l3')}</li>
      </ul>
      <br />
      <div className="font-18-roman c-646464">
        {t('offices_studios.warranties.description3.d2')}
      </div>
    </>
  )

  const Fourth = (
    <>
      <div className="font-48-roman c-414141">
        {t('offices_studios.warranties.description4.title')}
      </div>
      <br />
      <div className="font-18-roman c-646464">
        {t('offices_studios.warranties.description4.d1')}
      </div>
    </>
  )

  return (
    <div className="d-grid custom-padding">
      {/* Intestazione */}
      <div className="c-414141 font-12-bold text-uppercase">
        {t('offices_studios.warranties.small_description')}
      </div>
      <hr className="c-414141 w-3-percent" />

      {/* Sezioni */}
      {First}
      <br />
      {Second}
      <br />
      {Third}
      <br />
      {Fourth}
    </div>
  )
}

export default OfficesStudiosWarranties
