import './documents.scss'

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { pathAtom, selectorDocuments } from 'src/storage'

export const useDocuments = () => {
  const { t } = useTranslation()
  const documents = useRecoilValue(selectorDocuments)
  const setPath = useSetRecoilState(pathAtom)

  useEffect(() => {
    setPath(location.pathname)
  }, [location.pathname, setPath])

  const openDocument = (base64: string, name: string) => {
    const link = document.createElement('a')
    link.href = `data:application/pdf;base64,${base64}`
    link.download = `${name}.pdf`
    link.target = '_blank'
    link.click()
  }

  return {
    t,
    documents,
    openDocument,
  }
}
