import i18n from 'src/i18n'
import * as yup from 'yup'

export const yupStr = yup.string()
export const yupStrRequired = yupStr.required(i18n.t('commons.required'))

export const checkIsFieldRequired = <T extends Record<string, string>>(
  schema: yup.ObjectSchema<T>
) => {
  const getRequiredFields = () => {
    const fields = schema?.describe()?.fields as Record<keyof T, yup.SchemaDescription>

    if (!fields) return undefined

    return Object?.entries(fields)?.reduce?.((newObj, [key, schemaDescription]) => {
      newObj[key as keyof T] = !schemaDescription?.optional
      return newObj
    }, {} as Record<keyof T, boolean>)
  }

  const requiredFields = getRequiredFields()

  const isFieldRequired = (field: keyof T): boolean => {
    return requiredFields?.[field] || false
  }

  return {
    isFieldRequired,
  }
}

export const formatLabel = (schema: yup.ObjectSchema<any>, id = '', label = '') => {
  const { isFieldRequired } = checkIsFieldRequired(schema)
  return isFieldRequired(id) ? `${label}* ` : `${label} `
}
