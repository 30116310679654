import { AxiosError } from 'axios'
import { AxiosGateCallbacks, AxiosGateError, AxiosGateRequest } from 'src/types'

import { useAxiosGateError } from './axios-gate-error.hook'
import { useAxiosGateLoader } from './axios-gate-loader.hook'
import { useAxiosGateRequest } from './axios-gate-request.hook'

export const useAxios = <T, D>(gateId: string) => {
  const { doRequest } = useAxiosGateRequest<T, D>(gateId)
  const { gateError, saveGateError, removeGateError } = useAxiosGateError(gateId)
  const { idle, pending, toIdle, toPending } = useAxiosGateLoader(gateId)

  const crossGate = async (
    request: AxiosGateRequest<T>,
    {
      onSuccess,
      onFailure = saveGateError,
      onSuccessResponse,
      onFailureResponse,
      onIdle = toIdle,
      onPending = toPending,
    }: AxiosGateCallbacks<D>
  ) => {
    try {
      onPending?.()
      const resp = await doRequest(request)
      onSuccess?.(resp?.data)
      onSuccessResponse?.(resp)
    } catch (err) {
      const axiosError = err as AxiosError<AxiosGateError>
      onFailure?.(axiosError?.response?.data)
      onFailureResponse?.(axiosError?.response)
    } finally {
      onIdle?.()
    }
  }

  return { gateError, removeGateError, idle, pending, crossGate }
}
