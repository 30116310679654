import { useRootOutcome } from 'src/storage'
import { AxiosGateError, RootOutcome } from 'src/types'

export const useAxiosGateError = (gateId: string) => {
  const {
    rootOutcome,
    saveRootOutcome,
    removeRootOutcome: removeGateError,
  } = useRootOutcome(gateId)

  const gateError = rootOutcome?.axiosGateError

  const toRootOutcome = (axiosGateError?: AxiosGateError): RootOutcome => ({
    success: false,
    title: '🚧 TODO - map your title',
    subtitle: '🚧 TODO - map your subtitle',
    axiosGateError,
  })

  const saveGateError = (gateError?: AxiosGateError) => {
    saveRootOutcome(toRootOutcome(gateError))
  }

  return { gateError, saveGateError, removeGateError }
}
