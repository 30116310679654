import Cookies from 'js-cookie'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { useGetDossier } from 'src/networking/terminals'
import { useGetIdToken } from 'src/networking/terminals/token/token.gates'
import { atomDossier } from 'src/storage'
import { PrivateRouteProps, typedMemo } from 'src/types'
import { Error404 } from 'src/ui/pages'
import { useBoolean } from 'src/utils'

export const PrivateRoute = typedMemo(({ element }: PrivateRouteProps): JSX.Element => {
  const [enabled, allow] = useBoolean(false)

  const [dossier, saveDossier] = useRecoilState(atomDossier)

  const { crossGate: getIdToken, pending: pendingIdToken } = useGetIdToken()
  const { crossGate: getDossier, pending: pendingDossier } = useGetDossier()

  const location = useLocation()
  const params = new URLSearchParams(location.search)

  const dossierId = params.get('dossierId') || dossier?.dossierId

  useEffect(() => {
    if (!Cookies.get('IdToken')) {
      getIdToken(
        {
          headers: {
            'Content-Type': 'application/x-amz-json-1.1',
            'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth',
          },
          data: {
            AuthFlow: process.env.REACT_APP_AUTHFLOW || '',
            AuthParameters: {
              USERNAME: process.env.REACT_APP_USERNAME || '',
              PASSWORD: process.env.REACT_APP_PASSWORD || '',
            },
            ClientId: process.env.REACT_APP_CLIENT_ID || '',
            UserPoolId: process.env.REACT_APP_USER_POOL_ID || '',
          },
        },
        {
          onSuccess: (response) => {
            if (
              response?.AuthenticationResult?.TokenType &&
              response?.AuthenticationResult?.IdToken
            ) {
              Cookies.set(
                'IdToken',
                `${response?.AuthenticationResult?.TokenType} ${response?.AuthenticationResult?.IdToken}`
              )
            }
          },
        }
      )
    }

    if (dossierId && dossierId !== dossier?.dossierId) {
      getDossier(
        {
          headers: {
            xDossierId: dossierId,
          },
        },
        {
          onSuccess: (dossier) => {
            saveDossier(dossier)
            allow()
          },
        }
      )
    } else if (dossierId && dossierId === dossier?.dossierId) {
      allow()
    }
  }, [])

  return enabled ? element : pendingIdToken || pendingDossier ? <></> : <Error404 />
})
