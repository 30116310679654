import Cookies from 'js-cookie'
import { useRecoilValue } from 'recoil'
import { useAws } from 'src/networking/aws'
import { atomDossier } from 'src/storage'
import { AwsGateCallbacks, AwsGateRequest, GetSede, GetSedi } from 'src/types'

export const useGetSedi = <T extends GetSedi['request'], D extends GetSedi['response']>() => {
  const hook = useAws<T, D>('getSedi')
  const dossier = useRecoilValue(atomDossier)

  return {
    ...hook,
    crossGate: (request: AwsGateRequest<T>, callbacks: AwsGateCallbacks<D>) => {
      request.headers = {
        ...request.headers,
        Authorization: Cookies.get('IdToken') || '',
        xDossierId: dossier?.dossierId,
      }
      return hook.crossGate(
        {
          method: 'get',
          path: `/sedi`,
          ...request,
        },
        callbacks
      )
    },
  }
}

export const useGetSede = <T extends GetSede['request'], D extends GetSede['response']>() => {
  const hook = useAws<T, D>('getSede')
  const dossier = useRecoilValue(atomDossier)

  return {
    ...hook,
    crossGate: (request: AwsGateRequest<T>, callbacks: AwsGateCallbacks<D>) => {
      request.headers = {
        ...request.headers,
        Authorization: Cookies.get('IdToken') || '',
        xDossierId: dossier?.dossierId,
      }
      return hook.crossGate(
        {
          method: 'get',
          path: `/sedi/${request.uriParams.idSede}`,
          ...request,
        },
        callbacks
      )
    },
  }
}
