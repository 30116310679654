import { useOfficeStudiosDescription } from './offices-studios-description.hook'

const OfficesStudiosDescription = () => {
  const { t } = useOfficeStudiosDescription()

  return (
    <div className="d-grid custom-padding">
      <div className="c-414141 font-12-bold text-uppercase">
        {t('offices_studios.small_description')}
      </div>
      <hr className="c-414141 w-3-percent" />
      <div className="font-48-roman c-414141">{t('offices_studios.big_description')}</div>
      <br />
      <div className="font-18-roman c-646464">{t('offices_studios.description')}</div>
      <br />
      <div className="font-18-roman c-646464">{t('offices_studios.description2')}</div>
    </div>
  )
}

export default OfficesStudiosDescription
