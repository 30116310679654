import { Optional } from 'src/types'

export const deleteKey = <T extends Record<string, unknown>>(record: T, key = ''): T => {
  const recordCopy = deepCopy(record)
  delete recordCopy?.[key]
  return recordCopy
}

export const deleteEmptyKeys = <T extends Record<string, unknown>>(record: T): T => {
  const recordCopy = deepCopy(record)
  Object.entries(recordCopy).forEach(([key, value]) => {
    if (value === null || value === undefined || value === '') {
      delete recordCopy?.[key]
    }
  })
  return recordCopy
}

export const deepCopy = <T>(thing: T): T => {
  try {
    return JSON.parse(JSON.stringify(thing))
  } catch (e) {
    return thing
  }
}

export const delay = (ms: number) => new Promise((res) => setTimeout(res, ms))

export function capitalizeCase(str: string) {
  if (str.length === 0) return str
  const firstLetter = str.charAt(0).toUpperCase()
  const restOfWord = str.slice(1).toLowerCase()
  return firstLetter + restOfWord
}

export const concatClasses = (...classes: Optional<string>[]) => {
  return classes?.filter((x) => x)?.join(' ')
}

export const currencyFormatter = (amount: number) => {
  if (isNaN(amount)) {
    return ''
  }
  return new Intl.NumberFormat('it-IT', {
    style: 'currency',
    currency: 'EUR',
  }).format(amount)
}

export const toDate = (timestamp: number | undefined) => {
  if (!timestamp) return null
  const date = new Date(timestamp)
  return date.toLocaleDateString('it-IT', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    timeZone: 'UTC',
  })
}

export const toHour = (timestamp: number | undefined) => {
  if (!timestamp) return null
  const date = new Date(timestamp)
  return date.toLocaleTimeString('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'UTC',
  })
}

export const fillTemplate = (template: string, values: Record<string, string>) => {
  return template.replace(/{{(\w+)}}/g, (_, key) => values[key] || '')
}
