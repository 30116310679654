import './faqs.scss'

import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import { ItasMinusIcon, PlusIcon } from 'src/assets'
import { Paths } from 'src/navigation'
import { Button } from 'src/ui/widgets'

import { useFaqs } from './faqs.hook'

const Faqs = () => {
  const { t, faqs, activeKey, setActiveKey, location, lowerLanguage } = useFaqs()

  const faqItems = faqs?.map((faq, index) => (
    <Card key={index} className="mt-4">
      <Card.Header
        as={Card.Header}
        eventkey={index.toString()}
        onClick={() => handleToggle(index.toString())}
      >
        <div className="d-flex justify-content-between align-items-center cursor-pointer">
          <p className="font-32-thin dynamic-question-title p-3 mb-0 col-9 col-md-9">
            {faq.request[lowerLanguage]}
          </p>
          <Button
            iconRight={activeKey === index.toString() ? ItasMinusIcon : PlusIcon}
            iconSize={25}
            classes={`${
              location.pathname === Paths.PaymentSuccess
                ? 'bg-none align-self-center col-md-2'
                : 'bg-none align-self-center col-md-1'
            }`}
          />
        </div>
      </Card.Header>
      <Accordion.Collapse eventKey={index.toString()}>
        <Card.Body className="font-16-roman">
          {faq.answer[lowerLanguage]?.split('\n').map((line, index) => (
            <p className="m-0" key={index}>
              {line}
            </p>
          ))}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  ))

  const Title =
    location.pathname === Paths.PaymentSuccess ? (
      <h2 className="font-48-light c-292929 mb-5">
        {t('policy_configuration.faqs.thank_you_page_title')}
      </h2>
    ) : (
      <h2 className="font-48-light c-292929 mb-5">{t('policy_configuration.faqs.title')}</h2>
    )
  const SubTitle = (
    <p className="col-md-6 font-18-roman c-646464">{t('policy_configuration.faqs.subtitle')}</p>
  )

  const handleToggle = (key: string) => {
    setActiveKey(activeKey === key ? '' : key)
  }
  return (
    <div className={`${location.pathname === Paths.PaymentSuccess ? 'd-md-flex' : ''}`}>
      <div className={`${location.pathname === Paths.PaymentSuccess ? 'col-md-6' : ''}`}>
        {Title}
        {SubTitle}
      </div>
      <div className={`${location.pathname === Paths.PaymentSuccess ? 'col-md-6' : ''}`}>
        <Accordion flush activeKey={activeKey}>
          {faqItems}
        </Accordion>
      </div>
    </div>
  )
}

export default Faqs
