import { useRecoilValue } from 'recoil'

import { atomRootLoaders } from './root-loaders.atoms'

export const useRootLoaders = () => {
  const rootLoaders = useRecoilValue(atomRootLoaders)

  const someRootPending = Object.values(rootLoaders)?.some(
    (rootLoader) => (rootLoader?.pendingCounter || 0) > 0
  )

  return { rootLoaders, someRootPending }
}
