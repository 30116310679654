import { useState } from 'react'

export const useBoolean = (initialState = false): [boolean, () => void, () => void, () => void] => {
  const [state, setState] = useState(initialState)

  const turnOn = () => setState(true)
  const turnOff = () => setState(false)
  const toggle = () => setState((x) => !x)

  return [state, turnOn, turnOff, toggle]
}
