import { LeftArrowIcon, RightArrowIcon } from 'src/assets'
import { ButtonProps } from 'src/types'

export const flavors: Record<string, ButtonProps> = {
  // Primary
  primary: {
    classes: 'primary',
  },
  primaryFlip: {
    classes: 'primary-flip',
  },
  primaryBack: {
    classes: 'primary',
    classesIconLeft: 'filter-ffffff',
    iconLeft: LeftArrowIcon,
  },
  primaryNext: {
    classes: 'primary',
    classesIconRight: 'filter-ffffff',
    iconRight: RightArrowIcon,
  },
  primaryFlipBack: {
    classes: 'primary-flip',
    classesIconLeft: 'filter-7c2d35',
    iconLeft: LeftArrowIcon,
  },
  primaryFlipNext: {
    classes: 'primary-flip',
    classesIconRight: 'filter-7c2d35',
    iconRight: RightArrowIcon,
  },
  primaryFlipBackBorder: {
    classes: 'primary-flip-border',
    classesIconLeft: 'filter-7c2d35',
    iconLeft: LeftArrowIcon,
  },
  primaryFlipNextBorder: {
    classes: 'primary-flip-border',
    classesIconRight: 'filter-7c2d35',
    iconRight: RightArrowIcon,
  },

  // Accent
  accent: {
    classes: 'accent',
  },
  accentFlip: {
    classes: 'accent-flip',
  },
  accentBack: {
    classes: 'accent',
    classesIconLeft: 'filter-ffffff',
    iconLeft: LeftArrowIcon,
  },
  accentNext: {
    classes: 'accent',
    classesIconRight: 'filter-ffffff',
    iconRight: RightArrowIcon,
  },
  accentFlipBack: {
    classes: 'accent-flip',
    classesIconLeft: 'filter-059881',
    iconLeft: LeftArrowIcon,
  },
  accentFlipNext: {
    classes: 'accent-flip',
    classesIconRight: 'filter-059881',
    iconRight: RightArrowIcon,
  },
  accentFlipBackBorder: {
    classes: 'accent-flip-border',
    classesIconLeft: 'filter-059881',
    iconLeft: LeftArrowIcon,
  },
  accentFlipNextBorder: {
    classes: 'accent-flip-border',
    classesIconRight: 'filter-059881',
    iconRight: RightArrowIcon,
  },

  // Transparent primary
  transparentPrimary: {
    classes: 'transparent-primary',
  },
  transparentPrimaryBack: {
    classes: 'transparent-primary',
    classesIconLeft: 'filter-7c2d35',
    iconLeft: LeftArrowIcon,
  },
  transparentPrimaryNext: {
    classes: 'transparent-primary',
    classesIconRight: 'filter-7c2d35',
    iconRight: RightArrowIcon,
  },

  // Transparent accent
  transparentAccent: {
    classes: 'transparent-accent',
  },
  transparentAccentBack: {
    classes: 'transparent-accent',
    classesIconLeft: 'filter-059881',
    iconLeft: LeftArrowIcon,
  },
  transparentAccentNext: {
    classes: 'transparent-accent',
    classesIconRight: 'filter-059881',
    iconRight: RightArrowIcon,
  },

  // Transparent white
  transparentWhite: {
    classes: 'transparent-white',
  },
  transparentWhiteBorder: {
    classes: 'transparent-white-border',
  },
}
