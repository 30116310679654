import './loader.scss'

import { Image, Modal } from 'react-bootstrap'
import { LoaderIcon } from 'src/assets'
import { LoaderProps, typedMemo } from 'src/types'
import { concatClasses } from 'src/utils'

export const Loader = typedMemo(({ overlay, classes }: LoaderProps) => {
  return overlay ? (
    <Modal centered show={overlay} animation={false} className="loader-modal">
      <Image src={LoaderIcon} className={concatClasses('loader filter-ffffff', classes)} />
    </Modal>
  ) : (
    <Image src={LoaderIcon} className={concatClasses('loader filter-059881', classes)} />
  )
})
