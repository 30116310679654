import { Container } from 'react-bootstrap'
import { typedMemo } from 'src/types'

import { useCookie } from './cookie-policy.hook'

export const CookiePolicy = typedMemo(() => {
  const { t } = useCookie()

  return (
    <div>
      <Container fluid>
        <div className="mt-5">
          <p>
            Su questo sito usiamo tecnologie per raccogliere informazioni utili a migliorare la Sua
            esperienza online. La presente policy si riferisce all&apos;uso dei cookie&nbsp;e alle
            modalit&agrave; attraverso le quali poterli gestire. Ci riserviamo di modificare questa
            policy in qualsiasi momento. Qualunque cambiamento nella presente policy avr&agrave;
            effetto dalla data di pubblicazione sul Sito. Durante la Sua navigazione sul sito web
            www.convenzioni.gruppoitas.it (il "Sito"), ITAS Mutua, in qualit&agrave; di titolare del
            trattamento (il "Titolare"), tratter&agrave; i Suoi dati personali tramite
            l&apos;utilizzo di cookie. Il Titolare attribuisce una rilevante attenzione alla
            protezione dei Suoi dati personali e, nel presente documento ("Cookie Policy") Le
            fornisce informazioni sul trattamento dei Suoi dati personali effettuati tramite i
            cookie. Inoltre, il Titolare potr&agrave; altres&igrave; trattare altri Suoi dati
            personali e La invita, pertanto, a prendere altres&igrave; visione dell&apos;informativa
            completa accessibile al seguente{' '}
            <a href="/TODO/public/documents/it/InformativaWeb_it.pdf">link</a> .
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>1. CHI &Egrave; IL TITOLARE DEL TRATTAMENTO</strong>
          </p>
          <p>&nbsp;</p>
          <p>
            Il Titolare del trattamento &egrave; ITAS Mutua, con sede legale in Piazza delle Donne
            Lavoratrici n. 2 - Trento, nella persona del legale rappresentante pro tempore (il
            "Titolare" o "ITAS"). La informiamo inoltre che, ove azionabili, Lei potr&agrave;
            esercitare i diritti di cui agli artt. 15-22 del Regolamento Europeo 679/2016,
            nonch&egrave; ricevere qualsiasi informazione relativa al trattamento dei Suoi dati
            personali e/o alla presente Informativa, scrivendo a: ITAS Mutua - Piazza delle Donne
            Lavoratici, 2 38122 Trento oppure al seguente indirizzo e-mail: privacy@gruppoitas.it Il
            Titolare ha altres&igrave; nominato un Responsabile della protezione dei dati ("DPO")
            che Lei potr&agrave; contattare inviando una mail a:{' '}
            <a href="mailto:dpo@gruppoitas.it">dpo@gruppoitas.it</a>
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>2.&nbsp;COSA SONO I COOKIE</strong>
          </p>
          <p>&nbsp;</p>
          <p>
            I cookie sono file di testo che un sito internet invia ai terminali degli utenti e che
            consentono al gestore del sito di memorizzare alcuni dati trasmessi dal browser
            utilizzato dall&apos;utente in fase di navigazione. I cookie sono utilizzati per
            eseguire autenticazioni informatiche, monitorare sessioni e memorizzare informazioni
            specifiche riguardanti gli utenti che accedono al server e, di norma, sono presenti nel
            browser di ciascun utente in numero molto elevato. Nel corso della navigazione su un
            sito, l&apos;utente pu&ograve; ricevere sul suo terminale anche cookie che vengono
            inviati da siti o da web server diversi (c.d. "terze parti"), sui quali possono
            risiedere alcuni elementi (quali, ad esempio, immagini, mappe, suoni, specifici link a
            pagine di altri domini) presenti sul sito che lo stesso sta visitando
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>3. CHE TIPOLOGIE&nbsp;DI COOKIE ESISTONO</strong>
          </p>
          <p>&nbsp;</p>
          <p>Esistono sei principali tipologie di cookie:</p>
          <p>&nbsp;</p>
          <p>
            <em>a) Cookie tecnici non persistenti di sessione di PRIMA PARTE</em>
          </p>
          <p>
            I cookie tecnici sono quelli utilizzati al solo fine di "effettuare la trasmissione di
            una comunicazione su una rete di comunicazione elettronica, o nella misura strettamente
            necessaria al fornitore di un servizio WEB esplicitamente richiesto dall&apos;utente a
            erogare tale servizio". Essi non vengono utilizzati per scopi ulteriori e sono
            normalmente installati direttamente dal titolare o gestore del sito web. Possono essere
            suddivisi in cookie di navigazione o di sessione, che garantiscono la normale
            navigazione e fruizione del sito web (permettendo, ad esempio, di realizzare un acquisto
            o autenticarsi per accedere ad aree riservate). Per l&apos;installazione di tali cookie
            non &egrave; richiesto il preventivo consenso degli utenti. I session cookie contengono
            l&apos;informazione che viene utilizzata nella Sua sessione corrente del browser. Questi
            cookie vengono automaticamente eliminati alla chiusura del browser. Nulla viene
            conservato sul Suo computer oltre il tempo di utilizzazione del sito.&nbsp;
          </p>
          <p>&nbsp;</p>
          <p>
            <em>b) Cookie tecnici persistenti di PRIMA PARTE</em>
          </p>
          <p>
            I cookie persistenti, invece, vengono utilizzati per mantenere l&apos;informazione che
            viene utilizzata nel periodo tra un accesso e l&apos;altro al sito web, o utilizzati per
            finalit&agrave; tecniche e per migliorare la navigazione sul sito. Questo dato permette
            ai siti di riconoscere che Lei &egrave;&nbsp;utente o visitatore gi&agrave; noto e si
            adatta di conseguenza. I cookie "persistenti" hanno una durata che viene fissata dal
            sito web e che pu&ograve; variare da pochi minuti a diversi anni e vengono quindi
            memorizzati fino alla loro scadenza o cancellazione.&nbsp;
          </p>
          <p>&nbsp;</p>
          <p>
            <em>c) Cookie analitici di PRIMA PARTE</em>
          </p>
          <p>
            I cookie analitici, anche detti "analytics", consentono di realizzare delle dettagliate
            statistiche sui visitatori di un sito web come, per esempio, la visualizzazione di
            determinate pagine, il numero di visitatori, il tempo trascorso sul sito dagli utenti e
            le modalit&agrave; di arrivo. I cookie analitici sono assimilati ai cookie tecnici
            laddove utilizzati direttamente dal gestore del sito per raccogliere informazioni in
            forma aggregata sul numero degli utenti e su come questi visitano il sito stesso.&nbsp;
          </p>
          <p>&nbsp;</p>
          <p>
            <em>d) Cookie analitici di TERZA PARTE con IP anonimizzato</em>
          </p>
          <p>
            In questo caso il sistema di statistiche ("Analytics") anonimizza l&apos;indirizzo non
            appena ci&ograve; &egrave; tecnicamente possibile nel passaggio pi&ugrave; a monte della
            rete in cui avviene la raccolta dei dati. In genere la funzione di anonimizzazione IP
            imposta l&apos;ultimo ottetto di indirizzi IP dell&apos;utente IPV4 e gli ultimi 80 bit
            degli indirizzi IPV6 su zero in memoria subito dopo l&apos;invio alla rete di raccolta
            Analytics. In questo caso l&apos;indirizzo IP completo non &egrave; mai scritto su
            disco.&nbsp;
          </p>
          <p>&nbsp;</p>
          <p>
            <em>e) Cookie analitici di TERZA PARTE con IP NON anonimizzato</em>
          </p>
          <p>
            I cookie analitici, anche detti "analytics", consentono di realizzare delle dettagliate
            statistiche sui visitatori di un sito web come per esempio, la visualizzazione di
            determinate pagine, il numero di visitatori, il tempo trascorso sul sito dagli utenti e
            le modalit&agrave; di arrivo. La normativa europea e italiana prevede che Lei debba
            prestare il Suo consenso all&apos;installazione di tali cookie sul suo device.&nbsp;
          </p>
          <p>&nbsp;</p>
          <p>
            <em>f) Cookie di profilazione di TERZA PARTE</em>
          </p>
          <p>
            I cookie di terze parti profilanti, in modo indipendente e da noi non direttamente
            controllabile, potrebbero essere utilizzati&nbsp;per raccogliere informazioni sulle
            attivit&agrave; da Lei compiute durante la navigazione delle pagine di questo sito.
            Queste informazioni potrebbero essere utilizzate per proporLe pubblicit&agrave; in linea
            con i Suoi interessi, sulla base dei contenuti da Lei visitati, o per misurare
            l&apos;efficacia delle campagne pubblicitarie. Tali cookie potrebbero essere contenuti
            in vari elementi presenti all&apos;interno della pagina web, come ad esempio banner
            pubblicitari, immagini, video, ecc. Un esempio sensibile &egrave; costituito dalla
            presenza dei cosiddetti "social plugin" o dei "social sharing buttons" dei principali
            social network (Facebook, Twitter, Google+ e LinkedIn), finalizzati alla condivisione
            dei contenuti della pagina web sui social network stessi. L&apos;utilizzo dei dati
            raccolti tramite i cookie di terze parti, sui quali noi non esercitiamo alcun tipo di
            controllo, &egrave; disciplinato dalle relative informative alle quali il Titolare
            rimanda nel successivo punto 4 della presente Cookie Policy, cui Le&nbsp;chiediamo di
            fare riferimento. La normativa europea e italiana prevede che Lei debba prestare il Suo
            consenso all&apos;installazione di tali cookie sul Suo device.&nbsp;
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>4. COOKIE POLICY DI TERZE PARTI</strong>
          </p>
          <p>&nbsp;</p>
          <p>
            I cookie di terze parti sono creati e leggibili da domini esterni al presente sito. Di
            seguito il Titolare indica i cookie di terze parti installati tramite il presente sito e
            La invita a fare riferimento alle rispettive cookie policy per comprendere le
            modalit&agrave; e finalit&agrave; del trattamento perseguite tramite gli stessi.&nbsp;
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>
              5. QUALI COOKIE UTILIZZA IL PRESENTE SITO WEB E QUALI SONO LE LORO PRINCIPALI
              CARATTERISTICHE
            </strong>
          </p>
          <p>&nbsp;</p>
          <p>Il presente sito web pu&ograve; inviare all&apos;utente i seguenti&nbsp;cookie:</p>
          <p>&nbsp;</p>
        </div>
        <div id="ot-sdk-cookie-policy" />
        <button id="ot-sdk-btn" className="ot-sdk-show-settings">
          {t('commons.cookie_label')}
        </button>
        <div className="mt-5 mb-5">
          <p>
            <strong>6. GESTIONE DEI COOKIES</strong>
          </p>
          <p>&nbsp;</p>
          <p>
            Il Titolare La informa che Lei potr&agrave; in ogni caso impedire l&apos;installazione
            di qualsiasi tipologia di cookie tramite le impostazioni del Suo browser. Tuttavia, nel
            caso in cui Lei scelga di eliminare o rifiutare anche i cookie tecnici, la Sua decisione
            potr&agrave; influire su diverse caratteristiche o servizi del Sito, rallentando o
            disturbando la Sua navigazione. La informiamo, inoltre, che &egrave; sempre possibile
            cancellare i cookie installati sul Suo dispositivo tramite le impostazioni del Suo
            browser.
          </p>
          <p>
            Al fine di verificare come impedire l&apos;installazione dei cookie o cancellare i
            cookie gi&agrave; presenti sul Suo dispositivo, il Titolare La invita a visionare le
            seguenti istruzioni a seconda del browser da Lei utilizzato.
          </p>
          <p>&nbsp;</p>
          <p>
            Chrome
            <br />
            Per informazioni vai a:{' '}
            <a href="https://support.google.com/accounts/answer/61416?hl=it">Supporto Chrome</a>
          </p>
          <p>
            Microsoft Edge
            <br />
            Per informazioni vai a:{' '}
            <a href="https://support.microsoft.com/it-it/microsoft-edge/eliminare-i-cookie-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09">
              Supporto Edge
            </a>
          </p>
          <p>
            Mozilla Firefox
            <br />
            Per informazioni vai a:&nbsp;
            <a href="https://support.mozilla.org/it/kb/protezione-antitracciamento-avanzata-firefox-desktop?redirectslug=Attivare+e+disattivare+i+cookie&amp;redirectlocale=it#w_come-modificare-leimpostazioni-dei-cookie">
              Supporto Firefox
            </a>
          </p>
          <p>
            Internet Explorer
            <br />
            Per informazioni vai a:&nbsp;
            <a href="https://support.microsoft.com/it-it/windows/eliminare-e-gestire-i-cookie-168dab11-0753-043d-7c16-ede5947fc64d">
              Supporto Internet Explorer
            </a>
          </p>
          <p>
            Safari
            <br />
            Per informazioni vai a:{' '}
            <a href="https://support.apple.com/it-it/HT201265">Supporto Safari</a>
          </p>
          <p>
            Opera
            <br />
            Per informazioni vai a:&nbsp;
            <a href="https://help.opera.com/en/latest/web-preferences/#cookies">Supporto Opera</a>
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>7. CONCESSIONE CONSENSO SU COOKIE</strong>
          </p>
          <p>&nbsp;</p>
          <p>
            In occasione del primo accesso al sito, appare un banner che contiene
            un&apos;informativa breve e tre pulsanti denominati "Impostazioni cookie", "Rifiuta
            tutti" e "Accetta tutti i cookie" per il tramite dei quali &egrave; possibile,
            rispettivamente, accedere alla maschera di gestione delle preferenze per l&apos;utilizzo
            dei cookie (dove potr&agrave; selezionare o deselezionare le singole categorie di
            cookie), prestare il consenso all&apos;utilizzo di tutti i cookie o negare il consenso a
            tutti i cookie diversi da quelli tecnici.
            <br />
            In qualunque momento, Lei potr&agrave; modificare le proprie preferenze in relazione
            all&apos;utilizzo dei cookie cliccando sul pulsante "Impostazioni cookie" riportato al
            punto 5 della presente informativa e accessibile tramite il link "Cookie policy e
            impostazioni" presente in calce ad ogni pagina del sito.
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>8. QUALI DIRITTI LEI HA IN QUALIT&Agrave; DI INTERESSATO</strong>
          </p>
          <p>&nbsp;</p>
          <p>
            In relazione ai trattamenti descritti nella presente Informativa, in qualit&agrave; di
            Interessato Lei potr&agrave; esercitare i diritti sanciti dagli articoli dal GDPR e, in
            particolare:
          </p>
          <ul>
            <li>
              diritto di accesso &ndash; diritto di ottenere conferma che sia o meno in corso un
              trattamento di dati personali che La riguardano e, in tal caso, ottenere
              l&apos;accesso ai Suoi dati personali â€“ compresa una copia degli stessi.
            </li>
            <li>
              diritto di rettifica &ndash; diritto di ottenere, senza ingiustificato ritardo, la
              rettifica dei dati personali inesatti che La riguardano e/o l&apos;integrazione dei
              dati personali incompleti.
            </li>
            <li>
              diritto alla cancellazione (diritto all&apos;oblio) &ndash; diritto di ottenere, senza
              ingiustificato ritardo, la cancellazione dei dati personali che La riguardano.
            </li>
            <li>
              diritto di limitazione di trattamento &ndash; diritto di ottenere la limitazione del
              trattamento, quando ricorrano i presupposti di Legge.
            </li>
            <li>
              diritto alla portabilit&agrave; dei dati &ndash; diritto di ricevere, in un formato
              strutturato, di uso comune e leggibile da un dispositivo automatico, i dati personali
              che La riguardano forniti al Titolare e il diritto di trasmetterli a un altro Titolare
              senza impedimenti, qualora il trattamento si basi sul consenso e sia effettuato con
              mezzi automatizzati. Inoltre, il diritto di ottenere che i Suoi dati personali siano
              trasmessi direttamente da&nbsp;ITAS ad altro Titolare qualora ci&ograve; sia
              tecnicamente fattibile.
            </li>
            <li>
              diritto di opposizione &ndash; diritto di opporsi, in qualsiasi momento, al
              trattamento dei dati personali che La riguardano basati sulla condizione di
              legittimit&agrave; del legittimo interesse, compresa la profilazione, salvo che
              sussistano motivi legittimi per il Titolare di continuare il trattamento che
              prevalgono sugli interessi, sui diritti e sulle libert&agrave; dell&apos;Interessato
              oppure per l&apos;accertamento, l&apos;esercizio o la difesa di un diritto in sede
              giudiziaria.
            </li>
            <li>
              proporre reclamo all&apos;Autorit&agrave; Garante per la protezione dei dati
              personali.
            </li>
            <li>
              revocare il consenso prestato in ogni occasione e con la stessa facilit&agrave; con
              cui &egrave; stato fornito
            </li>
          </ul>
          <p>&nbsp;</p>
          <p>
            <strong>9. COME POTER ESERCITARE I PROPRI DIRITTI</strong>
          </p>
          <p>&nbsp;</p>
          <p>
            Lei potr&agrave; in qualsiasi momento esercitare i Suoi diritti inviando una
            comunicazione ai seguenti contatti:
          </p>
          <ul>
            <li>
              Servizio Privacy Gruppo ITAS c/o ITAS, Piazza delle Donne Lavoratrici n. 2, 38122
              Trento;
            </li>
            <li>
              e-mail: <a href="mailto:privacy@gruppoitas.it">privacy@gruppoitas.it</a>
            </li>
          </ul>
        </div>
      </Container>
    </div>
  )
})
