import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { lowerLanguage } from 'src/i18n'
import { selectorFaqs } from 'src/storage'

export const useFaqs = () => {
  const { t } = useTranslation()
  const [activeKey, setActiveKey] = useState('')
  const location = useLocation()
  const faqs = useRecoilValue(selectorFaqs)

  return {
    t,
    faqs,
    activeKey,
    setActiveKey,
    location,
    lowerLanguage,
  }
}
