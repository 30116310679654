import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export const usePolicyCard = () => {
  const { t } = useTranslation()
  const [showInfoModal, setShowInfoModal] = useState(false)

  const openDocument = (link = '') => {
    if (link) {
      window.open(link?.replaceAll('"', '').toString(), '_blank')
    }
  }

  return {
    t,
    showInfoModal,
    setShowInfoModal,
    openDocument,
  }
}
