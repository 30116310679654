import './offices-studios-form.scss'

import { useFormik } from 'formik'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { useGetSede } from 'src/networking/terminals'
import { usePostQuote } from 'src/networking/terminals/quote/quote.gates'
import { atomFormikOfficesAndStudios, atomSedi, selectorInsured } from 'src/storage'
import { PolicyType, Quote } from 'src/types'

import { useValidationSchemasOffices } from '../handlers/validation-schema-offices.hook'

export const useOfficeStudiosForm = () => {
  const { t } = useTranslation()
  const [selectedCard, setSelectedCard] = useState(PolicyType.SILVER)

  const sedi = useRecoilValue(atomSedi)

  const formikSedi = useRecoilValue(atomFormikOfficesAndStudios)

  const [showMoreInfo, setShowMoreInfo] = useState(false)
  const insured = useRecoilValue(selectorInsured)

  const [companies, setCompanies] = useState([{}])

  const { crossGate: getSede } = useGetSede()

  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [modalMessage, setModalMessage] = useState('')

  const { crossGate: postQuote } = usePostQuote()

  const navigate = useNavigate()

  const validationSchema = useValidationSchemasOffices()
  const formik = useFormik({
    initialValues: formikSedi,
    validationSchema,
    onSubmit: () => {
      submitData()
    },
  })

  const submitData = () => {
    const body: Quote = {
      telefono: formik.values.phone,
      emailMittente: formik.values.email,
      sedi: formik.values.companies.map((company) => ({
        insegna: company.companyName,
        partitaIva: company.vatNumber,
        indirizzo: company.address,
        cap: company.zipCode,
        comune: company.city,
        provincia: company.province,
      })),
      coperture: [
        {
          type: PolicyType.SILVER,
          incendioContenuto: 10000,
          incendioRicorsoTerzi: 100000,
          furtoContenuto: 2000,
          lastre: 1000,
          incendioFabbricato: 0,
          danniElettrici: 0,
          premio: 82,
        },
        {
          type: PolicyType.GOLD,
          incendioContenuto: 10000,
          incendioRicorsoTerzi: 100000,
          furtoContenuto: 2000,
          lastre: 1000,
          incendioFabbricato: 50000,
          danniElettrici: 1500,
          premio: 120,
        },
        {
          type: PolicyType.CUSTOM,
          incendioContenuto: 0,
          incendioRicorsoTerzi: 0,
          furtoContenuto: 0,
          lastre: 0,
          incendioFabbricato: 0,
          danniElettrici: 0,
          premio: 0,
        },
      ],
      polizzaSelezionata: formik.values.selectedPolicy,
      consenso: formik.values.consentInformativeSet,
    }
    postQuote(
      {
        body: body,
      },
      {
        onSuccess: (response) => {
          setShowSuccessModal(true)
          setModalMessage(response.message)
        },
      }
    )
  }

  const handleSuccessModalClose = () => {
    window.location.reload()
    setShowSuccessModal(false)
  }

  const addCompanyForm = () => {
    const newCompany = {
      companyName: '',
      vatNumber: '',
      address: '',
      zipCode: '',
      city: '',
      province: '',
    }
    setCompanies([...companies, newCompany])
    formik.setFieldValue('companies', [...formik.values.companies, newCompany])
  }

  const removeCompanyForm = () => {
    const updatedCompanies = [...companies]
    updatedCompanies.pop()
    setCompanies(updatedCompanies)
    formik.setFieldValue('companies', updatedCompanies)
  }

  const handleCompanyChange = (index: number, field: string, value: string) => {
    const updatedCompanies = [...companies]
    updatedCompanies[index] = { ...updatedCompanies[index], [field]: value }
    setCompanies(updatedCompanies)
    formik.setFieldValue(`companies[${index}].${field}`, value)
  }

  const getSedeDetails = (idSede: string) => {
    getSede(
      {
        uriParams: {
          idSede,
        },
      },
      {
        onSuccess: (sede) => {
          const formikSede = {
            companyName: sede.insegna,
            vatNumber: sede.partitaIva,
            address: sede.indirizzo,
            zipCode: sede.cap,
            city: sede.comune,
            province: sede.provincia,
          }
          Object.entries(formikSede).forEach(([field, value]) => {
            formik.setFieldValue(`companies[0].${field}`, value)
          })
          setCompanies([formikSede])
        },
      }
    )
  }

  return {
    t,
    formik,
    validationSchema,
    selectedCard,
    setSelectedCard,
    sedi,
    showMoreInfo,
    setShowMoreInfo,
    insured,
    addCompanyForm,
    removeCompanyForm,
    companies,
    handleCompanyChange,
    getSedeDetails,
    showSuccessModal,
    handleSuccessModalClose,
    modalMessage,
  }
}
export { PolicyType }
