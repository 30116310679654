import axios from 'axios'
import { useMockend } from 'src/networking/mockend'
import { AxiosGateRequest, AxiosGateResponse } from 'src/types'

export const useAxiosGateRequest = <T, D>(gateId: string) => {
  const { isMocked, getMock } = useMockend()

  const doRequest = async (gateRequest: AxiosGateRequest<T>): Promise<AxiosGateResponse<D>> => {
    const { headers } = gateRequest

    const request = {
      ...gateRequest,
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': 'it',
        ...headers,
      },
    }

    if (isMocked(gateId)) {
      const { status, data } = await getMock(gateId)
      console.log('[FROM MOCK]: ', '\nGATE: ', gateId, '\nREQUEST: ', request, '\nRESPONSE: ', data)
      return { status, data } as AxiosGateResponse<D>
    }

    // console.log('[PARAMETERS AXIOS]: ', request)

    return await axios.request(request)
  }

  return { doRequest }
}
