import './payment-failure.scss'

import { Image } from 'react-bootstrap'
import { WarningIcon } from 'src/assets'
import { Button } from 'src/ui/widgets'

import { usePaymentFailure } from './payment-failure.hook'

export const PaymentFailure = () => {
  const { t, goBack } = usePaymentFailure()

  const FailureHeader = () => {
    return (
      <div>
        <Image src={WarningIcon} width={40} className="filter-7c2d35 mt-5 mb-4" />
        <h1 className="col-md-8 mb-4 failure-dynamic-size-title">{t('thank_you.failure.title')}</h1>
        <p className="font-24 c-7f7f7f col-md-8 mb-4">{t('thank_you.failure.description')}</p>

        <Button
          text={t('commons.go_home')}
          classes="bg-7c2d35 p-3 justify-content-center mb-5"
          classesText="text-uppercase font-16 c-ffffff"
          onPress={goBack}
        />
      </div>
    )
  }

  return (
    <>
      <div className="m-5 p-md-5">
        <FailureHeader />
      </div>
    </>
  )
}
