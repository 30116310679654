import './offices-studios.scss'

import { useEffect } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { usePutDossier } from 'src/networking/terminals'
import { atomDossier, atomOfficesDossier } from 'src/storage'
import { Dossier } from 'src/types'
import { useBoolean } from 'src/utils'

export const useOfficeStudios = () => {
  const oldDossier = useRecoilValue(atomDossier)
  const saveDossier = useSetRecoilState(atomOfficesDossier)
  const { crossGate: putDossier, pending: pendingDossier } = usePutDossier()
  const [enabled, allow] = useBoolean(false)

  useEffect(() => {
    putDossier(
      {
        headers: {
          xDossierId: oldDossier.dossierId,
        },
        body: {
          productCode: 'US_01',
        },
      },
      {
        onSuccess: (newDossier) => {
          substituteDossier(newDossier)
        },
      }
    )
  }, [])

  const substituteDossier = (newDossier: Dossier) => {
    saveDossier(newDossier)
    allow()
  }

  return {
    enabled,
    pendingDossier,
  }
}
