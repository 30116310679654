import Cookies from 'js-cookie'
import { useRecoilValue } from 'recoil'
import { useAws } from 'src/networking/aws'
import { atomDossier } from 'src/storage'
import { AwsGateCallbacks, AwsGateRequest, GetQuestionnaire, PostQuestionnaire } from 'src/types'

export const useGetQuestionnaire = <
  T extends GetQuestionnaire['request'],
  D extends GetQuestionnaire['response']
>() => {
  const hook = useAws<T, D>('getQuestionnaire')
  const dossier = useRecoilValue(atomDossier)

  return {
    ...hook,
    crossGate: (request: AwsGateRequest<T>, callbacks: AwsGateCallbacks<D>) => {
      request.headers = {
        ...request.headers,
        Authorization: Cookies.get('IdToken') || '',
        xDossierId: dossier?.dossierId,
      }
      return hook.crossGate(
        {
          method: 'get',
          path: `/polizze/${request.uriParams.idPolizza}/questionari/${request.uriParams.codQuestionario}`,
          ...request,
        },
        callbacks
      )
    },
  }
}

export const usePostQuestionnaire = <
  T extends PostQuestionnaire['request'],
  D extends PostQuestionnaire['response']
>() => {
  const hook = useAws<T, D>('postQuestionnaire')
  const dossier = useRecoilValue(atomDossier)

  return {
    ...hook,
    crossGate: (request: AwsGateRequest<T>, callbacks: AwsGateCallbacks<D>) => {
      request.headers = {
        ...request.headers,
        Authorization: Cookies.get('IdToken') || '',
        xDossierId: dossier?.dossierId,
      }
      return hook.crossGate(
        {
          method: 'post',
          path: `/polizze/${request.uriParams.idPolizza}/questionari/validate`,
          ...request,
        },
        callbacks
      )
    },
  }
}
