import { selector } from 'recoil'
import { Paths } from 'src/navigation'
import { Address, Carousel, Document, Faq, Insured, Optional } from 'src/types'

import { atomDossier, atomOfficesDossier, atomPolicy, pathAtom } from './data.atoms'

export const selectorFaqs = selector<Optional<Faq[]>>({
  key: 'faq',
  get: ({ get }) => get(atomDossier)?.faq,
})

export const selectorDocuments = selector<Optional<Document[]>>({
  key: 'documents',
  get: ({ get }) => {
    const path = get(pathAtom)
    if (path === Paths.OfficesAndStudios) {
      return get(atomOfficesDossier)?.documenti.filter((doc) => doc.docCode.startsWith('DOC'))
    }
    return get(atomDossier)?.documenti.filter((doc) => doc.docCode.startsWith('DOC'))
  },
})

export const selectorFooterDocuments = selector<Optional<Document[]>>({
  key: 'footerDocuments',
  get: ({ get }) => get(atomDossier)?.documenti.filter((doc) => doc.docCode.startsWith('FOOTER')),
})

export const selectorConsentDocuments = selector<Optional<Document[]>>({
  key: 'consentDocuments',
  get: ({ get }) => get(atomDossier)?.documenti.filter((doc) => doc.docCode.startsWith('CONS')),
})

export const selectorCarousel = selector<Optional<Carousel[]>>({
  key: 'carousel',
  get: ({ get }) => {
    const path = get(pathAtom)
    if (path === Paths.OfficesAndStudios) {
      return get(atomOfficesDossier)?.carousel
    }
    return get(atomDossier)?.carousel
  },
})

export const selectorInsured = selector<Optional<Insured>>({
  key: 'insured',
  get: ({ get }) => get(atomPolicy)?.assicurato,
})

export const selectorInsuredOfficesStudios = selector<Optional<Insured>>({
  key: 'insuredOfficesStudios',
  get: ({ get }) => get(atomOfficesDossier)?.assicurato,
  set: ({ set, get }, newValue) => {
    const currentDossier = get(atomOfficesDossier)
    if (currentDossier) {
      set(atomOfficesDossier, {
        ...currentDossier,
        assicurato: newValue as Optional<Insured>,
      })
    }
  },
})

export const selectorAddress = selector<Optional<Address>>({
  key: 'office',
  get: ({ get }) => get(atomPolicy)?.sede,
})
