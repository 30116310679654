import './info-section.scss'

import { WarningCard } from 'src/ui/fragments'
import { fillTemplate } from 'src/utils'

import { useInfoSection } from './info-section.hook'

export const InfoSection = () => {
  const { t, fills } = useInfoSection()

  return (
    <div>
      <h2 className="font-48-light c-292929 mb-5">
        {t('policy_configuration.info_section.title')}
      </h2>
      <div className="info-card">
        <p className="font-16-roman c-292929">{t('policy_configuration.info_section.t1')}</p>
        <div>
          <WarningCard
            warningTitle={t('policy_configuration.info_section.t3.title')}
            descriptionText={fillTemplate(
              t('policy_configuration.info_section.t3.description'),
              fills
            )}
            classTitle="font-16-bold c-292929"
            classDescription="font-14-roman c-646464"
          />
        </div>
      </div>
    </div>
  )
}
