import { selectorFamily, useRecoilState } from 'recoil'
import { RootLoader } from 'src/types'

import { atomRootLoaders } from './root-loaders.atoms'

const selectorRootLoader = selectorFamily<RootLoader, string>({
  key: 'rootLoader',

  get:
    (loaderId: string) =>
    ({ get }) =>
      get(atomRootLoaders)?.[loaderId] || { pendingCounter: 0 },

  set:
    (loaderId: string) =>
    ({ get, set }, rootLoader) => {
      set(atomRootLoaders, {
        ...get(atomRootLoaders),
        [loaderId]: rootLoader as RootLoader,
      })
    },
})

export const useRootLoader = (loaderId: string) => {
  const [rootLoader, saveRootLoader] = useRecoilState(selectorRootLoader(loaderId))

  const idle = rootLoader.pendingCounter === 0
  const pending = rootLoader.pendingCounter > 0

  const toIdle = () => saveRootLoader(({ pendingCounter: pc }) => ({ pendingCounter: pc - 1 }))
  const toPending = () => saveRootLoader(({ pendingCounter: pc }) => ({ pendingCounter: pc + 1 }))

  return { idle, pending, toIdle, toPending }
}
