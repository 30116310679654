export const ENV = process.env.REACT_APP_ENV
export const VERSION = process.env.REACT_APP_VERSION

export const MOCKEND_ALL_DISABLED = process.env.REACT_APP_MOCKEND_ALL_DISABLED === 'true'
export const MOCKEND_ALL_ENABLED = process.env.REACT_APP_MOCKEND_ALL_ENABLED === 'false'
export const MOCKEND_TIMING = 800

export const CONSOLE_LOG_ENABLED = true

export const API_NAME = process.env.REACT_APP_API_NAME || ''
export const ENDPOINT = process.env.REACT_APP_ENDPOINT
export const REGION = process.env.REACT_APP_REGION

export const ID_POLIZZA_PRINCIPALE = 1
export const ID_TUTELA_LEGALE = 2
export const ID_TUTELA_LEGALE_CIVILE_PENALE = 5
export const ID_PERIZIE_STIME = 3
