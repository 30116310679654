import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import IT from './locales/it.locale.json'

const resources = {
  it: {
    translation: IT,
  },
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      resources,
      fallbackLng: 'it',
      debug: true,
      interpolation: {
        escapeValue: false,
      },
    },
    () => {
      i18n.changeLanguage('it')
    }
  )

export type UpperLanguage = 'IT'
export type LowerLanguage = 'it'

export const country = i18n.language.slice(0, 2).toUpperCase()
export const upperLanguage = 'IT'
export const lowerLanguage = upperLanguage.toLowerCase() as LowerLanguage

export default i18n
