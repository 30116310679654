import { Amplify } from 'aws-amplify'
import { del, get, head, patch, post, put } from 'aws-amplify/api'
import awsExports from 'src/aws-exports'
import { API_NAME } from 'src/config'
import { useMockend } from 'src/networking/mockend'
import { AwsGateRequest, AwsGateResponse } from 'src/types'

Amplify.configure(awsExports)

export const useAwsGateRequest = <T, D>(gateId: string) => {
  const { isMocked, getMock } = useMockend()

  const doRequest = async ({ method = 'get', path = '', ...request }: AwsGateRequest<T>) => {
    if (isMocked(gateId)) {
      const { data } = await getMock(gateId)
      console.log('[FROM MOCK]: ', '\nGATE: ', gateId, '\nREQUEST: ', request, '\nRESPONSE: ', data)
      return data as AwsGateResponse<D>
    }
    // console.log('[PARAMETERS]: ', method, API_NAME, path, request)

    const apiName = API_NAME
    const options = request

    const handleResponse = async (restOperation: any) => {
      const { body } = await restOperation.response
      const response = await body.json()
      // console.log('[RESPONSE]: ', response)
      return response
    }

    switch (method) {
      case 'get':
        return handleResponse(get({ apiName, path, options }))
      case 'put':
        return handleResponse(put({ apiName, path, options }))
      case 'del':
        return handleResponse(del({ apiName, path, options }))
      case 'post':
        return handleResponse(post({ apiName, path, options }))
      case 'head':
        return handleResponse(head({ apiName, path, options }))
      case 'patch':
        return handleResponse(patch({ apiName, path, options }))
      default:
        throw new Error(`Unsupported method: ${method}`)
    }
  }

  return { doRequest }
}
