import { Image } from 'react-bootstrap'
import {
  AmexIcon,
  ApplePayIcon,
  MasterCardIcon,
  MyBankIcon,
  SatisPayIcon,
  VisaIcon,
} from 'src/assets'
import { FormikPolicy } from 'src/types/formik'

import { usePolicyConfigurationQuote } from './policy-configuration-quote.hook'

interface PolicyConfigurationQuoteProps {
  formikValues: FormikPolicy
}

export const PolicyConfigurationQuote = ({ formikValues }: PolicyConfigurationQuoteProps) => {
  const { t, policy, selectedWarranties, policyAmount, totalAmount, currencyFormatter } =
    usePolicyConfigurationQuote({
      formikValues,
    })

  const PaymentMethods = (
    <>
      <span className="font-12-roman c-292929">
        {t('policy_configuration.quote_summary.payment_method_label')}
      </span>
      <div className="d-flex justify-content-left mt-2 align-items-center mb-4">
        <Image src={VisaIcon} className="col-2" />
        <Image src={MasterCardIcon} className="col-2" />
        <Image src={MyBankIcon} className="col-2" />
        <Image src={AmexIcon} className="col-2" />
        <Image src={ApplePayIcon} className="col-2" />
        <Image src={SatisPayIcon} className="col-2" />
      </div>
    </>
  )

  const Title = <h3 className="font-24 fw-bold">{t('policy_configuration.quote_summary.title')}</h3>

  const PolicyDetails = (
    <div className="d-flex justify-content-between">
      <span className="col-9 font-16-roman c-292929">{policy?.descrizione}</span>
      <b className="text-nowrap font-16-bold c-292929">{currencyFormatter(Number(policyAmount))}</b>
    </div>
  )

  const AddedWarranties = (
    <>
      <h5 className="c-7f7f7f text-uppercase font-12-bold">
        {t('policy_configuration.quote_summary.added_warranties')}
      </h5>
      {selectedWarranties?.map((warranty, index) => (
        <div key={index} className="d-flex justify-content-between">
          <span className="font-16-roman c-292929">{warranty?.descrizione}</span>
          <div>
            <span className="font-16-bold c-292929">
              {currencyFormatter(Number(warranty?.selectedAmount))}
            </span>
          </div>
        </div>
      ))}
    </>
  )

  const Total = (
    <div className="d-flex justify-content-between align-items-center mb-3">
      <span className="font-16-roman c-292929">
        {t('policy_configuration.quote_summary.yearly_price_label')}
      </span>
      <b className="text-nowrap font-28-bold c-292929">{totalAmount}</b>
    </div>
  )

  return (
    <div className="quote-summary">
      {Title}
      {PolicyDetails}
      <hr />
      {AddedWarranties}
      <hr />
      {PaymentMethods}
      <hr />
      {Total}
    </div>
  )
}
