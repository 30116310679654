import { RightArrowIcon } from 'src/assets'
import { WarningCard } from 'src/ui/fragments'
import { Button, InputSelect } from 'src/ui/widgets'

import { useSection4 } from './section4.hook'

export const Section4 = () => {
  const {
    t,
    formik,
    sedi,
    showWarning,
    warningTitle,
    warningDescription,
    validationSchema,
    onPressBuyInsurance,
  } = useSection4()

  const { values, errors, touched, setFieldValue, setFieldTouched } = formik

  const TextSection = (
    <div className="col-md-3">
      <h2 className="text-left font-48-light c-292929">{t('landing.section4.title')}</h2>
      <div className="mt-4 d-flex align-items-center text-left font-18-roman c-646464">
        <p>{t('landing.section4.description')}</p>
      </div>
    </div>
  )

  const InsuranceWidget = (
    <div className="section4-insurance-widget col-md-8">
      {sedi.length > 0 && (
        <div className="mb-4">
          <InputSelect
            name="company"
            label={t('landing.section4.insurance_widget.company_selector_label')}
            options={sedi.map((sede) => ({
              id: sede.code.toString(),
              value: sede.description,
            }))}
            validationSchema={validationSchema}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            classesLabel="font-14-roman c-7f7f7f"
            touched={touched.company}
            value={values.company}
            error={errors.company}
            classesInput="section4-input font-16-roman"
          />
        </div>
      )}

      {showWarning && (
        <WarningCard warningTitle={warningTitle} descriptionText={warningDescription} />
      )}

      <Button
        text={t('landing.section4.button')}
        iconRight={RightArrowIcon}
        classesIconRight="ms-3 filter-ffffff"
        iconSize={18}
        classes="p-3 d-flex justify-content-center bg-7c2d35 section4-button mt-4"
        classesText="font-14-roman c-ffffff"
        disabled={!values.company || showWarning || sedi.length === 0}
        onPress={onPressBuyInsurance}
      />
    </div>
  )

  return (
    <div className="d-md-flex justify-content-between align-items-center">
      {TextSection}
      {InsuranceWidget}
    </div>
  )
}
