import i18n from 'src/i18n'
import { useRootOutcomes } from 'src/storage'
import { ModalFailure } from 'src/ui/fragments'

export const OutcomeRoot = () => {
  const { topRootOutcome, removeTopRootOutcome } = useRootOutcomes()

  return (
    <ModalFailure
      visible={!!topRootOutcome && !topRootOutcome?.success}
      description={
        topRootOutcome?.awsGateError?.errorDescription ||
        topRootOutcome?.awsGateError?.response?.data?.message ||
        i18n.t('error.page_error_description')
      }
      buttonHome={true}
      onClose={removeTopRootOutcome}
    />
  )
}
