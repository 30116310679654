import Cookies from 'js-cookie'
import { useRecoilValue } from 'recoil'
import { useAws } from 'src/networking/aws'
import { atomDossier } from 'src/storage'
import { AwsGateCallbacks, AwsGateRequest, GetPolicy, PostPolicy, PutPolicy } from 'src/types'

export const useGetPolicy = <T extends GetPolicy['request'], D extends GetPolicy['response']>() => {
  const hook = useAws<T, D>('getPolicy')
  const dossier = useRecoilValue(atomDossier)

  return {
    ...hook,
    crossGate: (request: AwsGateRequest<T>, callbacks: AwsGateCallbacks<D>) => {
      request.headers = {
        ...request.headers,
        Authorization: Cookies.get('IdToken') || '',
        xDossierId: dossier?.dossierId,
      }
      return hook.crossGate(
        {
          method: 'get',
          path: `/polizze/${request.uriParams.idPolizza}`,
          ...request,
        },
        callbacks
      )
    },
  }
}

export const usePostPolicy = <
  T extends PostPolicy['request'],
  D extends PostPolicy['response']
>() => {
  const hook = useAws<T, D>('postPolicy')
  const dossier = useRecoilValue(atomDossier)

  return {
    ...hook,
    crossGate: (request: AwsGateRequest<T>, callbacks: AwsGateCallbacks<D>) => {
      request.headers = {
        ...request.headers,
        Authorization: Cookies.get('IdToken') || '',
        xDossierId: dossier?.dossierId,
      }
      return hook.crossGate(
        {
          method: 'post',
          path: `/polizza`,
          ...request,
        },
        callbacks
      )
    },
  }
}

export const usePutPolicy = <T extends PutPolicy['request'], D extends PutPolicy['response']>() => {
  const hook = useAws<T, D>('putPolicy')
  const dossier = useRecoilValue(atomDossier)

  return {
    ...hook,
    crossGate: (request: AwsGateRequest<T>, callbacks: AwsGateCallbacks<D>) => {
      request.headers = {
        ...request.headers,
        Authorization: Cookies.get('IdToken') || '',
        xDossierId: dossier?.dossierId,
      }
      return hook.crossGate(
        {
          method: 'put',
          path: `/polizze/${request.uriParams.idPolizza}`,
          ...request,
        },
        callbacks
      )
    },
  }
}
