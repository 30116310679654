import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'
import i18n from 'src/i18n'
import { atomQuestionnaire } from 'src/storage'
import { array, boolean, object, string } from 'yup'

export const useValidationSchemas = () => {
  const { t } = useTranslation()
  const questionnaire = useRecoilValue(atomQuestionnaire)

  const step1 = object({
    warranties: array().test('non-null', t('commons.required'), (warranties) => {
      const pass = warranties?.[0]?.isSelected !== null && warranties?.[0]?.isSelected !== undefined
      return pass
    }),
  })

  const step2 = object().shape({
    questions: array()
      .of(
        object()
          .shape({
            value: string().required('Seleziona una risposta'),
          })
          .required()
      )
      .test('check-for-null', t('commons.required'), (questions) => {
        if (!questions || questions.length !== questionnaire?.questionario?.domande.length) {
          return false
        }
        for (let i = 0; i < questions?.length; i++) {
          if (questions?.[i]?.value === null || questions?.[i]?.value === undefined) {
            return false
          }
        }
        return true
      })
      .required('Le domande sono obbligatorie')
      .length(questionnaire?.questionario?.domande.length),
  })

  const step3 = object({
    consentDigitalTransmission: boolean()
      .oneOf([true], t('commons.required'))
      .required(t('commons.required')),
    consentInformativeSet: boolean()
      .oneOf([true], t('commons.required'))
      .required(t('commons.required')),
    consentPrivacy: boolean().oneOf([true], t('commons.required')).required(t('commons.required')),
    email: string()
      .email(i18n.t('commons.invalid_email'))
      .required(i18n.t('commons.email_required')),
    phone: string().matches(regexPhone, i18n.t('commons.invalid_phone')),
  })

  const step4 = object({})

  return [step1, step2, step3, step4]
}

export const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/gm

export const regexPhone = /^(\+\d{1,3}[-\s]?)?\d{3}[-\s]?\d{6,7}$/gm
