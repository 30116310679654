import './footer.scss'

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useRecoilValue } from 'recoil'
import { lowerLanguage } from 'src/i18n'
import { selectorFooterDocuments, useRootOutcomes } from 'src/storage'
import { Document } from 'src/types'

export const useFooter = () => {
  const MAPS_LINK = 'http://maps.google.com/?q='

  const { t } = useTranslation()

  const navigate = useNavigate()

  const { removeRootOutcomes } = useRootOutcomes()

  useEffect(() => {
    removeRootOutcomes()
  }, [])

  const documents = useRecoilValue(selectorFooterDocuments)

  const informativeWebDoc = documents?.find(({ docCode }) => docCode === 'FOOTER_RC_1')

  const claimLink = 'https://www.gruppoitas.it/it/reclami'

  const openDocumentInNewTab = (document: Document | undefined) => {
    if (document) {
      const base64 = document.filePath[lowerLanguage]
      const byteCharacters = atob(base64)
      const byteNumbers = new Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)
      const blob = new Blob([byteArray], { type: 'application/pdf' })
      const url = URL.createObjectURL(blob)
      window.open(url, '_blank')
    }
  }

  return {
    t,
    openInformativeWebDoc: () => openDocumentInNewTab(informativeWebDoc),
    openClaimLink: () => window.open(claimLink, '_blank'),
    MAPS_LINK,
    navigate,
    removeRootOutcomes,
  }
}
