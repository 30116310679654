/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

import {
  API_NAME,
  ENDPOINT,
  REGION
} from "./config";

const awsmobile = {
  aws_project_region: REGION,
  aws_cloud_logic_custom: [{
    name: API_NAME,
    endpoint: ENDPOINT,
    region: REGION,
  }, ]
};

export default awsmobile;