import './header.scss'

import { Container, Image, Navbar } from 'react-bootstrap'
import { LogoFiaip, LogoItas } from 'src/assets'
import { Paths } from 'src/navigation'
import { typedMemo } from 'src/types'

export const Header = typedMemo(() => {
  const Logos = (
    <>
      <Navbar.Brand href={Paths?.Landing}>
        <Image src={LogoFiaip} height={45} />
      </Navbar.Brand>
      <div className="navbar-separator d-none d-md-block" />
      <Navbar.Brand href={Paths?.Landing}>
        <Image src={LogoItas} />
      </Navbar.Brand>
    </>
  )

  return (
    <Navbar expand="lg" className="bg-ffffff header-navbar ps-md-3 pt-3 pb-3">
      <Container fluid>{Logos}</Container>
    </Navbar>
  )
})
