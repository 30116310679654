import { useAxios } from 'src/networking/axios'
import { AxiosGateCallbacks, AxiosGateRequest, GetIdToken } from 'src/types'

export const useGetIdToken = <
  T extends GetIdToken['request'],
  D extends GetIdToken['response']
>() => {
  const hook = useAxios<T, D>('getIdToken')

  return {
    ...hook,
    crossGate: (request: AxiosGateRequest<T>, callbacks: AxiosGateCallbacks<D>) => {
      return hook.crossGate(
        {
          method: 'post',
          baseURL: process.env.REACT_APP_TOKEN_PATH,
          ...request,
        },
        callbacks
      )
    },
  }
}
