import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Paths } from 'src/navigation'

export const usePaymentFailure = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return {
    t,
    goBack: () => navigate(Paths.PolicyConfiguration),
  }
}
