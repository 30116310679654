import './index.css'

import React from 'react'
import ReactDOM from 'react-dom/client'

import App from './App'
import reportWebVitals from './reportWebVitals'

const STRICT_MODE = false

const Strict = (
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

const NoStrict = <App />

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(STRICT_MODE ? Strict : NoStrict)

reportWebVitals()
