import './section4.scss'

import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { Paths } from 'src/navigation'
import { useGetSedi } from 'src/networking/terminals'
import { atomFormikSede, atomSedi } from 'src/storage'
import { yupStr } from 'src/utils'
import * as yup from 'yup'

export const useSection4 = () => {
  const { t } = useTranslation()

  const { crossGate: getSedi } = useGetSedi()

  const navigate = useNavigate()

  const [sedi, saveSedi] = useRecoilState(atomSedi)

  const [showWarning, setShowWarning] = useState(false)
  const [warningTitle, setWarningTitle] = useState('')
  const [warningDescription, setWarningDescription] = useState('')

  const [formikSedi, saveFormikSedi] = useRecoilState(atomFormikSede)

  const validationSchema = yup.object({
    company: yupStr,
  })

  const formik = useFormik({
    initialValues: formikSedi,
    validationSchema,
    onSubmit: () => {},
  })

  useEffect(() => {
    getSedi(
      {},
      {
        onSuccess: (response) => {
          saveSedi(response?.sedi)
          if (response?.sedi?.length === 0) {
            // case 3.a
            setWarningTitle(
              t('landing.section4.insurance_widget.warning_message.no_available_companies')
            )
            setShowWarning(true)
          } else if (response?.sedi?.length === 1) {
            // case 1.a
            formik.setFieldValue('company', response.sedi[0]?.code?.toString())

            // case 1.b
            if (response?.sedi?.[0]?.infoPolizza.length > 0) {
              setWarningTitle(
                t(
                  'landing.section4.insurance_widget.warning_message.not_available_for_selected_company'
                )
              )
              setWarningDescription(
                t(
                  'landing.section4.insurance_widget.warning_message.not_available_for_selected_company_description'
                )
              )

              setShowWarning(true)
            }
          } else {
            if (formik?.values?.company === undefined || formik?.values?.company === '') {
              const sedeEmptyPolicies = response.sedi.find(
                (company) => company?.infoPolizza?.length === 0
              )
              formik.setFieldValue('company', sedeEmptyPolicies?.code?.toString())
            }
            setShowWarning(false)
          }
        },
      }
    )
  }, [])

  useEffect(() => {
    if (sedi?.length > 0) {
      const sede = sedi.find(
        (company) => company?.code.toString() === formik?.values?.company?.toString()
      )

      // case 2.b
      if ((sede?.infoPolizza ?? []).length > 0) {
        setWarningTitle(
          t('landing.section4.insurance_widget.warning_message.not_available_for_selected_company')
        )
        setWarningDescription(
          t(
            'landing.section4.insurance_widget.warning_message.not_available_for_selected_company_description'
          )
        )
        setShowWarning(true)
      } else {
        // case 2.a
        setShowWarning(false)
      }
    }
  }, [formik?.values?.company, sedi])

  const onPressBuyInsurance = () => {
    saveFormikSedi(formik.values)
    navigate(Paths.PolicyConfiguration)
  }

  return {
    t,
    formik,
    sedi,
    showWarning,
    warningTitle,
    warningDescription,
    validationSchema,
    onPressBuyInsurance,
  }
}
