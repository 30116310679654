import './section1.scss'

import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'
import { atomDossier } from 'src/storage'

export const useSection1 = () => {
  const { t } = useTranslation()

  const dossier = useRecoilValue(atomDossier)

  return {
    t,
    dossier,
  }
}
