import Cookies from 'js-cookie'
import { useRecoilValue } from 'recoil'
import { useAws } from 'src/networking/aws'
import { atomDossier } from 'src/storage'
import { AwsGateCallbacks, AwsGateRequest, PatchEmail } from 'src/types'

export const usePatchMail = <
  T extends PatchEmail['request'],
  D extends PatchEmail['response']
>() => {
  const hook = useAws<T, D>('patchEmail')
  const dossier = useRecoilValue(atomDossier)

  return {
    ...hook,
    crossGate: (request: AwsGateRequest<T>, callbacks: AwsGateCallbacks<D>) => {
      request.headers = {
        ...request.headers,
        Authorization: Cookies.get('IdToken') || '',
        xDossierId: dossier?.dossierId,
      }
      return hook.crossGate(
        {
          method: 'patch',
          path: `/polizza/${request.uriParams.idPolizza}/email`,
          ...request,
        },
        callbacks
      )
    },
  }
}
