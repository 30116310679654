import './footer.scss'

import { upperLanguage } from 'src/i18n'
import { Paths } from 'src/navigation'
import { typedMemo } from 'src/types'

import { useFooter } from './footer.hook'

export const Footer = typedMemo(() => {
  const { t, MAPS_LINK, openInformativeWebDoc, openClaimLink, navigate } = useFooter()

  const FooterHeader = (
    <>
      <div className="ml-auto font-40-light c-ffffff">{t('footer.agency_label')}</div>
      <div className="ml-auto font-40-light c-ffffff">{t('footer.agency_name')}</div>

      <div className="mt-2">
        <div
          className="font-15 c-ffffff cursor-pointer"
          onClick={() => window.open(MAPS_LINK + t('footer.agency_address'))}
        >
          {t('footer.agency_address')}
        </div>
        <div className="d-flex d-md-block d-xl-flex">
          <div className="font-15 c-ffffff">{t('footer.agency_phome_label')}</div>
          <div
            className="font-15 c-ffffff ms-2 ms-md-0 ms-xl-2 cursor-pointer"
            onClick={() => window.open(`tel:${t('footer.green_number')}`)}
          >
            {t('footer.agency_phone')}
          </div>
        </div>
      </div>
    </>
  )

  const AssistanceInfo = (
    <div className="col-md-6">
      <div className="ml-auto mb-4 font-12-bold c-ffffff80 text-uppercase">
        {t('footer.title_1')}
      </div>

      <div className="mt-2">
        <div className="d-flex d-md-block d-xl-flex">
          <div className="font-15 c-ffffff">{t('footer.green_number_title')}</div>
          <div
            className="font-15 c-ffffff ms-2 ms-md-0 ms-xl-2 cursor-pointer"
            onClick={() => window.open(`tel:${t('footer.green_number')}`)}
          >
            {t('footer.green_number')}
          </div>
        </div>
        <br />
        <div className="d-flex d-md-block d-xl-flex">
          <div className="font-15 c-ffffff">{t('footer.email_title')}</div>
          <div
            className="font-15 c-ffffff ms-2 ms-md-0 ms-xl-2 cursor-pointer"
            onClick={() => window.open(`mailto:${t('footer.email')}`)}
          >
            {t('footer.email')}
          </div>
        </div>
      </div>
    </div>
  )

  const AgencyInfo = (
    <div className="col-md-6">
      <div className="ml-auto mb-4 mt-5 mt-md-0 font-12-bold c-ffffff80 text-uppercase">
        {t('footer.title_3')}
      </div>
      <div className="mt-2">
        <div className="font-15 c-ffffff">
          {t('footer.srl_label_1')}
          {t('footer.srl_label_number')}
          {t('footer.srl_label_2')}
        </div>

        <br />
        <div>
          <div className="font-15 c-ffffff">{t('footer.ivass_value_2')}</div>
          <div
            className="font-15 c-ffffff cursor-pointer"
            onClick={() => window.open(t('footer.ivass_link'))}
          >
            {t('footer.ivass_link')}
          </div>
        </div>
        <br />

        <div className="d-block d-xl-flex">
          <div className="font-15 c-ffffff">{t('footer.registered_office_title')}</div>
          <div
            className="font-15 c-ffffff ms-xl-2 cursor-pointer"
            onClick={() => window.open(MAPS_LINK + t('footer.registered_office'))}
          >
            {t('footer.registered_office')}
          </div>
        </div>

        <div className="d-block d-xl-flex">
          <div className="font-15 c-ffffff">{t('footer.operational_offices_title')}</div>
          <div
            className="font-15 c-ffffff ms-xl-2 cursor-pointer"
            onClick={() => window.open(MAPS_LINK + t('footer.operational_offices'))}
          >
            {t('footer.operational_offices')}
          </div>
        </div>

        <div className="d-flex">
          <div className="font-15 c-ffffff">{t('footer.phone_title')}</div>
          <div
            className="font-15 c-ffffff ms-2 cursor-pointer"
            onClick={() => window.open(`tel:${t('footer.phone')}`)}
          >
            {t('footer.phone')}
          </div>
        </div>

        <div className="d-flex">
          <div className="font-15 c-ffffff">{t('footer.mail_title')}</div>
          <div
            className="font-15 c-ffffff ms-2 cursor-pointer"
            onClick={() => window.open(`mailto:${t('footer.mail')}`)}
          >
            {t('footer.mail')}
          </div>
        </div>

        <div className={upperLanguage === 'IT' ? 'd-flex' : 'd-block d-xl-flex'}>
          <div className="font-15 c-ffffff">{t('footer.pec_title')}</div>
          <div
            className={
              upperLanguage === 'IT'
                ? 'font-15 c-ffffff ms-2 cursor-pointer'
                : 'font-15 c-ffffff cursor-pointer'
            }
            onClick={() => window.open(`mailto:${t('footer.pec')}`)}
          >
            {t('footer.pec')}
          </div>
        </div>
      </div>
    </div>
  )

  const FooterTop = (
    <div className="bg-000000E6 justify-content-md-between py-4 px-3 pt-md-4 pb-1 px-md-5">
      <div>
        {FooterHeader}
        <div className="footer-line mb-3 mt-5" />
        <div className="d-md-flex justify justify-content-md-between mt-5">
          {AssistanceInfo}
          {AgencyInfo}
          <div />
        </div>
        <br />
      </div>
    </div>
  )

  const FooterBottom = (
    <div className="bg-000000E6 py-4 px-3 pt-4 pt-md-1 px-md-5">
      <div className="c-ffffff text-uppercase font-12-bold c-ffffff80">
        {t('footer.privacy_subtitle')}
      </div>
      <div className="footer-line mb-3 mt-4" />
      <div className="d-md-flex">
        <div className="mt-1 me-4 text-uppercase font-12-bold c-ffffff80 cursor-pointer">
          {t('footer.copy_right')}
        </div>
        <div
          className="mt-1 me-4 text-uppercase font-12-bold c-ffffff cursor-pointer"
          onClick={() => {
            navigate(Paths.CookiePolicy)
          }}
        >
          {t('footer.privacy_title_1')}
        </div>
        <div
          className="mt-1 me-4 text-uppercase font-12-bold c-ffffff cursor-pointer"
          onClick={() => {
            openInformativeWebDoc()
          }}
        >
          {t('footer.privacy_title_2')}
        </div>
        <div
          className="mt-1 me-4 text-uppercase font-12-bold c-ffffff cursor-pointer"
          onClick={() => {
            openClaimLink()
          }}
        >
          {t('footer.privacy_title_3')}
        </div>
      </div>
    </div>
  )

  return (
    <div className="footer">
      {FooterTop}
      {FooterBottom}
    </div>
  )
})
