import './landing.scss'

import { Helmet } from 'react-helmet'

import { useLanding } from './landing.hook'
import { Section1, Section2, Section3, Section4, Section5, Section6 } from './sections'

export const Landing = () => {
  const { t } = useLanding()

  return (
    <div className="bg-f5f5f5">
      <Helmet>
        <title>{t('meta.landing.title')}</title>
        <meta name="description" content={t('meta.landing.description')} />
      </Helmet>
      <div className="section-margin-large">
        <Section1 />
      </div>
      <hr className="landing-separator" />
      <div className="section-margin-large mb-md-5 pb-5">
        <Section2 />
      </div>
      <div className="section-padding-small mb-5">
        <Section3 />
      </div>
      <div className="section-padding-small section4-padding bg-e5e5e5">
        <Section4 />
      </div>
      <div className="section-padding-small section5-padding">
        <Section5 />
      </div>
      <hr className="landing-separator-large" />
      <div className="section-padding-small landing-margin">
        <Section6 />
      </div>
    </div>
  )
}
