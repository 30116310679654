import './policy-configuration.scss'

import { LeftArrowIcon, RightArrowIcon } from 'src/assets'
import { Documents, ProgressBar, WarningCard } from 'src/ui/fragments'
import { Button, Modal } from 'src/ui/widgets'

import Faqs from '../faqs/faqs'
import { usePolicyConfiguration } from './policy-configuration.hook'
import {
  InfoSection,
  PolicyConfigurationQuote,
  PolicyConfigurationStep1,
  PolicyConfigurationStep2,
  PolicyConfigurationStep3,
} from './sections'

export const PolicyConfiguration = () => {
  const {
    t,
    formik,
    policy,
    dossier,
    currentStep,
    selectedSede,
    formattedAddress,
    validationSchemas,
    showUnsuitablePurchase,
    nextStep,
    onGoBackPressed,
    setShowUnsuitablePurchase,
    setShowModalTutelaLegale,
    showModalTutelaLegale,
  } = usePolicyConfiguration()

  const { handleSubmit } = formik

  const BackSection = (
    <div className="mb-4">
      <Button
        iconSize={18}
        text={t('commons.go_back')}
        iconLeft={LeftArrowIcon}
        classesIconLeft="filter-7c2d35 me-3"
        classes="p-0 mb-3 d-flex justify-content-center bg-none"
        classesText="c-7c2d35 text-uppercase"
        onPress={onGoBackPressed}
      />
      <h1 className="policy-configuration-dynamic-size-title font-64-bold mb-4">
        {dossier?.prodottoDesc}
      </h1>
      <h2 className="policy-configuration-dynamic-size-subtitle font-32-bold mb-3">
        {selectedSede?.description}
      </h2>
      <div className="font-16-roman c-646464">{policy?.sede?.formaGiuridica}</div>
      <div className="font-16-roman c-646464">{formattedAddress}</div>
      <div className="font-16-roman c-646464">P.Iva: {policy?.sede?.partitaIva}</div>
    </div>
  )

  const ModalUnsuitablePurchase = (
    <Modal
      visible={true}
      classes="d-md-flex justify-content-center pb-5 pe-5 ps-5 pt-0"
      onClose={() => setShowUnsuitablePurchase(false)}
    >
      <div>
        <div className="d-md-flex justify-content-left">
          <h1 className="mb-0">
            {t('policy_configuration.step_2.unsuitable_purchase_modal.title')}
          </h1>
        </div>
        <div className="mt-3">
          <WarningCard
            warningTitle={''}
            descriptionText={t('policy_configuration.step_2.unsuitable_purchase_modal.description')}
            descriptionText2={t(
              'policy_configuration.step_2.unsuitable_purchase_modal.description2'
            )}
          />
        </div>
        <Button
          text={t('commons.continue')}
          onPress={() => setShowUnsuitablePurchase(false)}
          classes="bg-7c2d35 p-4 justify-content-center mt-4"
          iconRight={RightArrowIcon}
          classesIconRight="filter-ffffff"
          classesText="text-uppercase font-16 c-ffffff me-3"
        />
      </div>
    </Modal>
  )

  const ModalTutelaLegale = (
    <Modal
      visible={true}
      classes="d-md-flex justify-content-left pb-5 pe-5 ps-5 pt-0"
      onClose={() => setShowModalTutelaLegale(false)}
    >
      <div>
        <div className="d-md-flex justify-content-left">
          <h1>{t('policy_configuration.step_1.modal_tutela_legale.title')}</h1>
        </div>
        <div className="m-3 ms-0 font-14 c-939393">
          {t('policy_configuration.step_1.modal_tutela_legale.description')}
        </div>
        <Button
          text={t('commons.continue')}
          onPress={() => {
            setShowModalTutelaLegale(false)
            nextStep()
          }}
          classes="bg-7c2d35 p-4 justify-content-left mt-4"
          iconRight={RightArrowIcon}
          classesIconRight="filter-ffffff"
          classesText="text-uppercase font-16 c-ffffff me-3"
        />
      </div>
    </Modal>
  )

  const renderStep = (currentStep: number) => {
    switch (currentStep) {
      case 1:
        return (
          <div>
            <PolicyConfigurationStep1
              formik={formik}
              validationSchema={validationSchemas[currentStep - 1]}
            />
            {showModalTutelaLegale && ModalTutelaLegale}
          </div>
        )
      case 2:
        return (
          <div>
            <PolicyConfigurationStep2
              formik={formik}
              validationSchema={validationSchemas[currentStep - 1]}
            />
            {showUnsuitablePurchase && ModalUnsuitablePurchase}
          </div>
        )
      case 3:
        return (
          <PolicyConfigurationStep3
            formik={formik}
            validationSchema={validationSchemas[currentStep - 1]}
          />
        )
      default:
        return null
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="d-md-flex">
        <div className="col-md-9 p-md-5 p-4">
          {BackSection}
          <hr />
          <ProgressBar currentStep={currentStep} />
          {renderStep(currentStep)}
          <hr className="policy-separator" />
          <InfoSection />
          <hr className="policy-separator" />
          <Faqs />
          <hr className="policy-separator" />
          <Documents />
        </div>
        <div className="col-md-3 p-5 bg-ffffff sidebar-quote">
          <PolicyConfigurationQuote formikValues={formik.values} />

          <Button
            type="submit"
            text={
              currentStep === 3
                ? `${t('policy_configuration.step_3.payment')}`
                : `${t('commons.continue')}`
            }
            onPress={nextStep}
            classes="bg-7c2d35 p-4 justify-content-center w-100"
            classesText="text-uppercase font-16 c-ffffff"
          />
        </div>
      </div>
    </form>
  )
}
