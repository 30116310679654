import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'
import { atomPolicy } from 'src/storage'
import { Warranty } from 'src/types'
import { FormikWarranty } from 'src/types/formik'
import { currencyFormatter } from 'src/utils'

interface ExtendedPolicy extends Warranty {
  selectedAmount: string
}
interface PolicyConfigurationQuoteProps {
  formikValues: any // Replace 'any' with the appropriate type for formikValues
}

export const usePolicyConfigurationQuote = ({ formikValues }: PolicyConfigurationQuoteProps) => {
  const { t } = useTranslation()

  const [selectedWarranties, setSelectedWarranties] = useState<ExtendedPolicy[]>([])
  const [totalAmount, setTotalAmount] = useState<string>('0,00 €')
  const [policyAmount, setPolicyAmount] = useState<string>('0,00 €')

  const policies = useRecoilValue(atomPolicy)

  const policy = policies?.garanzie?.find((item) => item.obbligatoria === true)
  const warranties = policies?.garanzie?.filter((item) => item.obbligatoria === false)

  useEffect(() => {
    //PRIMARY POLICY
    const selectedWarranty = formikValues?.warranties?.find(
      (warranty: FormikWarranty) => warranty?.warrantyId === policy?.idGaranzia
    )

    if (selectedWarranty) {
      const selectedMassimale = policy?.massimali?.find(
        (maximum) => maximum?.idMassimale.toString() === selectedWarranty?.maximumId
      )

      const newPolicyAmount = selectedMassimale?.premio?.toString() || '0,00 €'
      if (newPolicyAmount !== policyAmount) {
        setPolicyAmount(newPolicyAmount)
      }
    }

    //ADDITIONAL WARRANTIES
    const updatedSelectedWarranties = formikValues?.warranties
      ?.filter((warranty: FormikWarranty) => warranty?.isSelected)
      ?.map((selectedWarranty: FormikWarranty) => {
        const warrantyDetails = warranties?.find(
          (warrantyItem) => warrantyItem.idGaranzia === selectedWarranty.warrantyId
        )
        const selectedAmountDetails = warrantyDetails?.massimali?.find(
          (amount) => amount.idMassimale.toString() === selectedWarranty.maximumId
        )

        return {
          ...warrantyDetails,
          selectedAmount: selectedAmountDetails?.premio,
        }
      })

    if (JSON.stringify(updatedSelectedWarranties) !== JSON.stringify(selectedWarranties)) {
      setSelectedWarranties(updatedSelectedWarranties)
    }

    //TOTAL AMOUNT
    const totalAmount = updatedSelectedWarranties?.reduce(
      (acc: number, warranty: ExtendedPolicy) => {
        if (!warranty.selectedAmount) return acc
        return acc + parseFloat(warranty.selectedAmount)
      },
      parseFloat(policyAmount)
    )
    setTotalAmount(currencyFormatter(totalAmount))
  }, [formikValues, warranties, policy, policyAmount, selectedWarranties])

  return {
    t,
    policy,
    selectedWarranties,
    policyAmount,
    totalAmount,
    currencyFormatter,
  }
}
