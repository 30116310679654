import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useGetQuestionnaire } from 'src/networking/terminals'
import { atomPostPolicyResponse, atomQuestionnaire } from 'src/storage'

export const usePolicyConfigurationStep2 = () => {
  const { t } = useTranslation()

  const [questionnaire, setQuestionnaire] = useRecoilState(atomQuestionnaire)

  const policy = useRecoilValue(atomPostPolicyResponse)

  const { crossGate: getQuestionnaire } = useGetQuestionnaire()

  useEffect(() => {
    getQuestionnaire(
      {
        uriParams: {
          idPolizza: policy.polizza.code,
          codQuestionario: 'Q00',
        },
      },
      {
        onSuccess: (data) => {
          setQuestionnaire(data)
        },
      }
    )
  }, [])

  return {
    t,
    questionnaire,
  }
}
