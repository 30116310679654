import './progress-bar.scss'

import { useTranslation } from 'react-i18next'
import { ProgressBarProps } from 'src/types'

export const ProgressBar = ({ currentStep, totalSteps = 3 }: ProgressBarProps) => {
  const { t } = useTranslation()

  const renderSteps = () => {
    return Array.from({ length: totalSteps }, (_, index) => (
      <div key={index + 1} className={`custom-step ${index + 1 <= currentStep ? 'active' : ''}`} />
    ))
  }

  return (
    <div className="custom-progress-bar-container">
      <div className="custom-progress-bar">{renderSteps()}</div>
      <div className="custom-step-label font-12-semibold">
        {currentStep} {t('commons.progress_bar.label')} {totalSteps}
      </div>
    </div>
  )
}
