import './button.scss'

import { Image } from 'react-bootstrap'
import { ButtonProps, typedMemo } from 'src/types'
import { concatClasses } from 'src/utils'

import { flavors } from './button.flavors'

export const Button = typedMemo(
  ({
    id,
    text,
    flavor,
    type = 'button',
    disabled,
    iconLeft,
    iconRight,
    iconSize = 16,
    classes = '',
    classesText = 'text-uppercase',
    classesIconLeft = 'me-3',
    classesIconRight = 'ms-3',
    onPress,
  }: ButtonProps) => {
    const fl = flavors[flavor || '']

    const iconl = iconLeft || fl?.iconLeft
    const iconr = iconRight || fl?.iconRight

    const IconLeft = (
      <Image
        className={concatClasses(classesIconLeft, fl?.classesIconLeft)}
        src={iconl}
        width={iconSize}
        height={iconSize}
      />
    )

    const Text = <span className={concatClasses(classesText, fl?.classesText)}>{text}</span>

    const IconRight = (
      <Image
        className={concatClasses(classesIconRight, fl?.classesIconRight)}
        src={iconr}
        width={iconSize}
        height={iconSize}
      />
    )

    return (
      <button
        id={id}
        className={concatClasses(classes, fl?.classes)}
        type={type}
        disabled={disabled}
        onClick={onPress}
      >
        {iconl && IconLeft}
        {Text}
        {iconr && IconRight}
      </button>
    )
  }
)
