import { useRootOutcome } from 'src/storage'
import { AwsGateError, RootOutcome } from 'src/types'

export const useAwsGateError = (gateId: string) => {
  const {
    rootOutcome,
    saveRootOutcome,
    removeRootOutcome: removeGateError,
  } = useRootOutcome(gateId)

  const gateError = rootOutcome?.awsGateError

  const toRootOutcome = (awsGateError?: AwsGateError): RootOutcome => ({
    success: false,
    awsGateError,
  })

  const saveGateError = (gateError?: AwsGateError) => {
    saveRootOutcome(toRootOutcome(gateError))
  }

  return { gateError, saveGateError, removeGateError }
}
