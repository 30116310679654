import './offices-studios-description.scss'

import { useTranslation } from 'react-i18next'

export const useOfficeStudiosDescription = () => {
  const { t } = useTranslation()

  return {
    t,
  }
}
