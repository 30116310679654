import './landing.scss'

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useResetRecoilState } from 'recoil'
import { atomFormikPolicy } from 'src/storage'

import { useProgress } from '../policy-configuration/sections'

export const useLanding = () => {
  const { t } = useTranslation()

  const resetFormikPolicy = useResetRecoilState(atomFormikPolicy)
  const { setCurrentStep } = useProgress()

  useEffect(() => {
    resetFormikPolicy()
    setCurrentStep(1)
  }, [])
  return { t }
}
