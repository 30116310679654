import { useSection1 } from './section1.hook'

export const Section1 = () => {
  const { t, dossier } = useSection1()

  const Status = (
    <div className="text-uppercase bg-7c2d35 c-ffffff font-12-bold d-inline-block px-2 mb-4 mt-md-5">
      {t('landing.section1.status')}
    </div>
  )

  const Title = <div className="mb-5 section1-dynamic-size-title">{dossier.prodottoDesc}</div>

  const Subtitle = (
    <div className="c-646464 section1-dynamic-size-subtitle">{dossier.prodottoSottotitolo}</div>
  )

  return (
    <div>
      {Status}
      {Title}
      {Subtitle}
    </div>
  )
}
