import { LowerLanguage } from 'src/i18n'

export type Message = Record<LowerLanguage, string>

export type Token = {
  AuthenticationResult: {
    AccessToken: string
    ExpiresIn: number
    IdToken: string
    RefreshToken: string
    TokenType: string
  }
}

export type FaqsWrapper = {
  faqs: Faq[]
}

export type Product = {
  code: string
  description: string
}

export type Document = {
  docCode: string
  step: string
  desc: Message
  fileName: Message
  filePath: Message
}

export type Faq = {
  faqCode: string
  step: string
  request: Message
  answer: Message
}

export type Carousel = {
  carouselCode: string
  step: string
  imagePath: string
  title: Message
  description: Message
  link: Message
}

export type Dossier = {
  dossierId: string
  sessionId: string
  result: string
  message: string
  languages: string[]
  prodotto: Product
  prodottoDesc: string
  prodottoSottotitolo: string
  documenti: Document[]
  faq: Faq[]
  carousel: Carousel[]
  assicurato?: Insured
}

export type InfoPolizza = {
  code: string
  description: string
}

export type Sede = {
  code: number
  description: string
  infoPolizza: InfoPolizza[]
}

export type SedeDetail = {
  idSede: number
  insegna: string
  indirizzo: string
  cap: string
  comune: string
  provincia: string
  regione: string
  partitaIva: string
  formaGiuridica: string
  sede: string
}

export type InsuranceProposal = {
  dataInizio: number
  dataFine: number
  frazionamenti: Fractionation[]
  garanzie: Warranty[]
  premioTotale: number
  assicurato: Insured
  sede: Address
}

export type Fractionation = {
  id: number
  descrizione: string
  selected: boolean
}

export type Warranty = {
  idGaranzia: number
  descrizione: string
  obbligatoria: boolean
  codProdotto: number
  selected: boolean
  preselected: boolean
  massimali: Maximum[]
  attachment: Document | undefined
  linkAllegato: Message
  infoAggiuntive: Message
}

export type Maximum = {
  idMassimale: number
  tipologiaDitta: string
  valoreMassimale: number
  premio: number
  selected: boolean
  preselected: boolean
}

export type Insured = {
  idAssicurato: string
  nome: string
  cognome: string
  email: string
  telefono: string
  dataNascita: number
  codiceFiscale: string
  tipoPersona?: string
}

export type Address = {
  idSede: number
  insegna: string
  indirizzo: string
  cap: string
  comune: string
  provincia: string
  regione: string
  partitaIva: string
  formaGiuridica: string
  sede: string
}

export type Answer = {
  idRisposta: number
  descrizione: string
  valore: string
  corretta: boolean
  preselezionata: boolean
  selezionata: boolean
}

export type QuestionType = {
  codTipologia: string
  descrizione: string
}

export type Question = {
  idDomanda: number
  titolo: string
  descrizione: string
  tipologia: QuestionType
  obbligatoria: boolean
  attiva: boolean
  risposte: Answer[]
}

export type Questionnaire = {
  isOk: boolean
  domande: Question[]
}

export type QuestionnaireWrapper = {
  idQuestionario: number
  codQuestionario: string
  questionario: Questionnaire
}

export type DocumentsWrapper = {
  result: string
  message: string
  languages: string[]
  documento: Document
  faq: Faq
  carousel: Carousel
}

export type Quote = {
  telefono: string
  emailMittente: string
  sedi: Omit<SedeDetail, 'idSede' | 'regione' | 'formaGiuridica' | 'sede'>[]
  polizzaSelezionata: PolicyType
  coperture: Coverage[]
  consenso: boolean
}

export type Coverage = {
  type: PolicyType
  incendioContenuto: number
  incendioRicorsoTerzi: number
  furtoContenuto: number
  lastre: number
  incendioFabbricato: number
  danniElettrici: number
  premio: number
}

export enum PolicyType {
  SILVER = 'SILVER',
  GOLD = 'GOLD',
  CUSTOM = 'CUSTOM',
}
