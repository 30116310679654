import { PolicyConfigurationProps } from 'src/types'
import { InputRadio } from 'src/ui/widgets'

import { usePolicyConfigurationStep2 } from './policy-configuration-step-2.hook'

export const PolicyConfigurationStep2 = (props: PolicyConfigurationProps) => {
  const { t, questionnaire } = usePolicyConfigurationStep2()

  const { formik } = props

  const Title = <h3 className="mb-3 fw-light font-32">{t('policy_configuration.step_2.title')}</h3>

  const Subtitle = (
    <h6 className="mb-5 fw-light font-18">{t('policy_configuration.step_2.subtitle')}</h6>
  )

  const Questionnaire = (
    <div>
      {questionnaire?.questionario?.domande?.map((question, index) => {
        const showError =
          formik.touched.questions !== undefined &&
          formik.errors?.questions &&
          formik.values.questions?.[index]?.value === undefined

        return (
          <div key={index}>
            <h5 className="text-uppercase c-7f7f7f font-12-bold mb-4 mt-4">{question.titolo}</h5>
            <InputRadio
              name={`questions[${index}].value`}
              key={`questions.${index}.value`}
              label={question.descrizione}
              options={question.risposte.map((answer) => ({
                id: answer.idRisposta.toString(),
                value: answer.valore,
              }))}
              value={formik.values.questions?.[index]?.value || ''}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              validationSchema={props.validationSchema}
              error={showError ? t('commons.required') : ''}
              touched={formik.touched.questions !== undefined}
              classes="m-0 p-0 mt-4 mb-3"
              classesLabel="m-0 p-0 font-20-bold d-flex align-items-center mb-3"
              classesOption="pb-3"
            />
          </div>
        )
      })}
    </div>
  )

  return (
    <div>
      {Title}
      {Subtitle}
      <div className="bg-ffffff p-3 pe-5 ps-5">{Questionnaire}</div>
    </div>
  )
}
